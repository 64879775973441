import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import cross from "../../assets/img/cross.svg";
import close from "../../assets/img/Close.svg";
import { CheckBox } from "@mui/icons-material";

const PopupModal = ({ show, handleClose, handleConfirm, setDeletePermision }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsChecked(isChecked);
    setDeletePermision(isChecked);
  };

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "9998",
  };

  const popupStyle = {
    position: "fixed",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    zIndex: "9999",
    width: "90%",
    maxWidth: "500px",
    boxSizing: "border-box",
  };

  const closeIconStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  };

  const imageStyle = {
    width: "80px",
    marginBottom: "10px",
  };

  const imageStyle2 = {
    width: "25px",
  };

  const imageStyle3 = {
    width: "80px",
    margin: "auto",
  };

  const titleStyle = {
    fontSize: "24px",
    marginBottom: "10px",
    fontWeight: "600",
    textAlign: "center",
  };

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    flexWrap: "wrap",
  };

  const buttonStyle = {
    margin: "10px",
  };

  return (
    <>
      {show && (
        <>
          <div style={overlayStyle}></div>
          <div style={popupStyle}>
            <div style={imageStyle3}>
              <img style={imageStyle} src={cross} alt="Cross Icon" />
            </div>

            <div style={closeIconStyle} onClick={handleClose}>
              <img style={imageStyle2} src={close} alt="Close Icon" />
            </div>
            <h4 style={titleStyle}>Are you sure?</h4>
            <h5 style={{ textAlign: "center" }}>
              Do you really want to delete these records?<br /> This process cannot be undone.
            </h5>
            <div style={buttonContainerStyle}>
              <Button variant="secondary" style={buttonStyle} onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="danger" style={buttonStyle} onClick={handleConfirm}>
                Delete
              </Button>
            </div>
            <div className="check-box mt-3" style={{ textAlign: "center" }}>
              <label>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                &nbsp; Do not ask again.
              </label>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PopupModal;
