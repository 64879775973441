import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { baseUrl } from "../../config/config";
// vsavcadsvcsa
const Logout = () => {
  const history = useNavigate();
  useEffect(() => {
    fetch(baseUrl + "/logout", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => {
        history("/", { replace: true });
        if (res.status != 200) {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return null;
};

export default Logout;
