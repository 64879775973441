import React, { useState } from "react";
import { Button, Form } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import close from "../../assets/img/Vector.svg";
import apiService from "../../apiServices/apiService";

const AddRefrence = () => {
  const [formData, setFormData] = useState([{ name: "", niche: "" }]);
  // handale input change for woned link
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFormData = [...formData];
    updatedFormData[index][name] = value;
    setFormData(updatedFormData);
  };
  // this add more for the Woned
  const handleAddMore = () => {
    setFormData([...formData, { name: "", niche: "" }]);
  };
  // this handle remove for woned
  const handleRemove = (index) => {
    const links = [...formData];
    links.splice(index, 1);
    setFormData(links);
  };
  // this is use for add woned link
  const postOwnedLink = (e) => {
    e.preventDefault();
    apiService.postTrackLink(formData);
  };
  return (
    <>
      <Form method="POST" onSubmit={postOwnedLink}>
        <Form.Group className="form-group">
          <Form.Label> Link URL</Form.Label>
          <Form.Control
            required
            type="text"
            name="name"
            value={formData[0].name}
            onChange={(event) => handleInputChange(0, event)}
            placeholder="www.fremontcHousePainting.com"
          />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label style={{ marginTop: "5px" }}> Niche</Form.Label>
          <Form.Control
            required
            type="number"
            name="niche"
            value={formData[0].niche}
            onChange={(event) => handleInputChange(0, event)}
            placeholder="Paintings"
          />
        </Form.Group>

        {formData.slice(1).map((link, index) => (
          <div className="add-from-link" key={index}>
            <div>
              <Form.Group className="form-group">
                <div className="close-set">
                  <Form.Label> Link URL</Form.Label>
                  <Button
                    className="close-btn"
                    type="button"
                    onClick={() => handleRemove(index + 1)}
                  >
                    <img src={close} alt="Your Image" />
                  </Button>
                </div>
                <Form.Control
                  required
                  type="text"
                  name="name"
                  value={link.name}
                  onChange={(event) => handleInputChange(index + 1, event)}
                  placeholder="www.fremontcHousePainting.com"
                />
              </Form.Group>

              <Form.Group className="form-group" id={`niche-${index + 1}`}>
                <Form.Label style={{ marginTop: "5px" }}> Niche</Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="niche"
                  value={link.niche}
                  onChange={(event) => handleInputChange(index + 1, event)}
                  placeholder="Paintings"
                />
              </Form.Group>
            </div>
          </div>
        ))}

        <div className="mt-3 mt-3 d-flex mb-3">
          <Button className="backlink-button1" variant="primary" type="submit">
            Submit Link
          </Button>

          <Button
            className="backlink-button2 d-flex ms-auto align-items-center"
            type="button"
            onClick={handleAddMore}
          >
            <FontAwesomeIcon className="btn-icon" icon={faPlusCircle} /> Add
            More
          </Button>
        </div>
      </Form>
    </>
  );
};
export default AddRefrence;
