import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-bootstrap/Alert";
import {
  faPlusCircle,
  faUserPlus,
  faPen,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import close from "../../assets/img/Vector.svg";
import apiService from "../../apiServices/apiService";
import { EditContact } from "./EditContact";
// import { IoIosArrowDown } from "react-icons/io";

const EditModel = ({
  show,
  // handleCloseEditModal,
  // showEditModal,
  handleClose,
  userForEdit,
  // fetchContact,
  wonedId,
  fetchBacklinks,
}) => {
  const [contactLoader, setContactLoader] = useState(false);
  const [contactData, setContactData] = useState(null);
  const [formData, setFormData] = useState([
    {
      owned_url: "",
      referenced_url: null,
      referenced_url_name: "",
      contact: null,
      anchor_text: "",
      notes: "",
      exContact: null,
    },
  ]);
  const [useExistingContect, setExContact] = useState([false]);
  const [useNewContect, setNewContact] = useState([false]);
  const [loader, setLoader] = useState(false);
  const [showNewContactModal, setShowNewContactModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editUserData, setEdituserData] = useState({});
  const [refUrl, setRefUrl] = useState([]);
  const [reflinkData, setRefLinkData] = useState("");
  const [indexForContact, setIndex] = useState(null);
  // contact form submit
  const [contactForm, setContactForm] = useState([
    { first_name: "", last_name: "", email: "" },
  ]);
  const handleInputChangeContact = (index, event) => {
    const newFormData = [...contactForm];
    newFormData[index][event.target.name] = event.target.value;
    setContactForm(newFormData);
  };
  const handleAddMoreContacts = () => {
    setContactForm([
      ...contactForm,
      { first_name: "", last_name: "", email: "" },
    ]);
  };
  const handleRemoveContact = (index) => {
    const newFormData = [...contactForm];
    newFormData.splice(index, 1);
    setContactForm(newFormData);
  };
  // add contact
  const postContact = async (e) => {
    e.preventDefault();
    setContactLoader(true);
    const data = await apiService.postContactdata(contactForm);
    if (data?.data?.errors?.length === 0) {
      setTimeout(() => {
        setContactForm([{ first_name: "", last_name: "", email: "" }]);
        setFormData((prevFormData) => {
          return prevFormData.map((item, index) => {
            if (index === 0) {
              return {
                ...item,
                contact: data?.data?.successful_contacts[0],
              };
            }
            return item;
          });
        });

        handleCloseNewContactModal();
      }, 3000);
    } else {
      // this is the logic for remove added data form tha form aaray
      const indices = data?.response?.data?.errors?.map((error) => error.index);
      const indicesToKeep = indices || [];
      indicesToKeep?.sort((a, b) => b - a);
      const filteredFormData = contactForm.filter((item, index) =>
        indicesToKeep.includes(index)
      );
      setTimeout(() => {
        setContactForm(filteredFormData);
      }, 1000);
    }
    setContactLoader(false);
    fetchContact();
    fetchBacklinks();
  };
  //get contact
  const fetchContact = async () => {
    try {
      const data = await apiService.getContact();
      const refUrlData = await apiService.getRefUrl();
      setContactData(data);
      setRefUrl(refUrlData);
    } catch (error) {
      console.error("Error fetching contact data:", error);
    }
  };
  // fet refURl
  const fetRefUrl = async (id) => {
    try {
      const refUrlData = await apiService.getRequestedBacklinckById(id);
      setRefLinkData(refUrlData);
    } catch (error) {
      console.error("Error fetching refUrl data:", error);
    }
  };

  useEffect(() => {
    const getConact = async () => {
      if (userForEdit?.contact) {
        const response = await apiService.getContactById(
          userForEdit.contact || formData[0]?.contact?.id
        );
        setFormData((prevFormData) => [
          {
            owned_url: wonedId,
            referenced_url_name: userForEdit?.name,
            contact: response,
            anchor_text: userForEdit?.anchor_text,
            notes: userForEdit?.notes,
          },
          ...prevFormData.slice(1),
        ]);
      } else {
        setFormData((prevFormData) => [
          {
            owned_url: wonedId,
            referenced_url_name: userForEdit?.name,
            contact: null,
            anchor_text: userForEdit?.anchor_text,
            notes: userForEdit?.notes,
          },
          ...prevFormData.slice(1),
        ]);
      }
    };
    getConact();
  }, [wonedId, userForEdit]);

  useEffect(() => {
    fetchContact();
    fetRefUrl(userForEdit.id);
  }, []);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    setFormData((prevContactForm) => {
      const updatedContactForm = [...prevContactForm];
      {
        updatedContactForm[index] = {
          ...updatedContactForm[index],
          [name]: value,
        };
      }
      return updatedContactForm;
    });
  };

  const postRefrenceLink = async (e) => {
    e.preventDefault();
    const updatedData = {
      referenced_url: reflinkData.referenced_url,
      owned_url: formData[0]?.owned_url,
      notes: formData[0]?.notes ? formData[0]?.notes : "",
      anchor_text: formData[0]?.anchor_text ? formData[0]?.anchor_text : "",
      UI_only: {
        referenced_url_name: "",
        contact: formData[0]?.contact?.id ? formData[0]?.contact?.id : null,
      },
    };
    setLoader(true);
    try {
      await apiService.updateRequestedBacklinks(userForEdit.id, updatedData);
      setTimeout(() => {
        setFormData([
          {
            referenced_url_name: "",
            contact: null,
            anchor_text: "",
            notes: "",
            owned_url: wonedId.id,
          },
        ]);
        setNewContact(0, false);
        updateShowArray(0, false);
        setLoader(false);
        handleClose();
        fetchBacklinks();
      }, 2000);
    } catch (error) {
      console.error("Error during post:", error);
      setLoader(false);
    }
  };

  // this is use for the oprn and close the modal
  const handleAddNewContact = () => {
    setShowNewContactModal(true);
  };

  const handleCloseNewContactModal = () => {
    setShowNewContactModal(false);
  };

  const handleCloseEditModal = async () => {
    const response = await apiService.getContactById(formData[0]?.contact?.id);
    if (response) {
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[0] = {
          ...updatedFormData[0],
          contact: response,
        };
        return updatedFormData;
      });
      setShowEditModal(false);
    }
  };

  // handle edit contact
  const handleEdit = (data) => {
    setEdituserData(data?.contact);
    setShowEditModal(true);
  };

  const [showArray, setShowArray] = useState([false, false]);
  const [newContect, setNewContect] = useState({});

  const updateBooleanArray = (index, value) => {
    const currentContact = formData[index]?.contact;
    const newArray = [...showArray];
    newArray[index] =
      currentContact && currentContact.id === value.id
        ? [false, false]
        : currentContact && currentContact.id !== value.id
        ? [true, false]
        : [true, false];
    setShowArray(newArray);
    setNewContect(value);
  };

  const updateShowArray = (index, value, value2) => {
    const newArray = [...showArray];
    newArray[index] = [value, value2];
    setShowArray(newArray);
  };
  const updateExContact = (index, value) => {
    const newArray = [...useExistingContect];
    newArray[index] = value;
    setExContact(newArray);
  };
  const updateNewContact = (index, value) => {
    if (index >= 0 && value === true) {
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          contact: newContect,
        };
        return updatedFormData;
      });
    } else {
      console.error("Invalid index provided:", index);
    }
  };

  const handleCloseModal = () => {
    setFormData([
      {
        owned_url: wonedId.id,
        referenced_url: null,
        referenced_url_name: null,
        contact: null,
        anchor_text: "",
        notes: "",
        exContact: null,
      },
    ]);
    setNewContact(0, false);
    updateExContact(0, false);
    updateShowArray(0, false);
    handleClose();
  };

  return (
    <>
      <Modal
        show={show && !showNewContactModal && !showEditModal}
        onHide={handleCloseModal}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div className="model-top-sec">
            <div className="model-tittle-1">Edit Expected Backlink Source</div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form method="POST" onSubmit={postRefrenceLink}>
            <div className="add-from-link">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Form.Group
                    className="form-group"
                    style={{
                      marginTop: "0px",
                      marginBottom: "5px",
                      height: "88px",
                    }}
                  >
                    <Form.Label className="model-form-label">
                      Backlink Source <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="referenced_url_name"
                      disabled
                      className="dropdown-addref-sec1"
                      value={
                        formData[0]?.referenced_url
                          ? formData[0]?.referenced_url?.name
                          : formData[0]?.referenced_url_name
                      }
                    />
                  </Form.Group>
                </div>

                <div className="col-12 col-lg-6">
                  <Form.Group
                    className="form-group"
                    style={{ marginTop: "0px", marginBottom: "5px" }}
                  >
                    <Form.Label className="model-form-label">
                      {" "}
                      Desired Anchor Text
                    </Form.Label>
                    <Form.Control
                      // required
                      type="text"
                      name="anchor_text"
                      value={formData[0]?.anchor_text}
                      onChange={(event) => handleInputChange(0, event)}
                      placeholder="Enter anchor text"
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <Form.Group
                  className="form-group"
                  style={{
                    marginTop: "0px",
                    marginBottom: "5px",
                    height: "88px",
                  }}
                >
                  <Form.Label className="model-form-label">Contact</Form.Label>
                  {newContect &&
                    newContect?.first_name !== "Select a contact" &&
                    showArray[0][0] === true && (
                      <Alert show={showArray && showArray[0][0] === true}>
                        <div className="arlt-main-box">
                          <span className="alrt-inf0-icon">
                            <FontAwesomeIcon icon={faInfo} />
                          </span>
                          <span className="alrt-inf0-text">
                            Do you want to change the contact for this site to{" "}
                            {`${newContect?.first_name} ${newContect?.last_name}`}{" "}
                          </span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Button
                            className="alrt-btn"
                            variant="outline-danger"
                            onClick={() => {
                              updateShowArray(0, false, false);
                              updateNewContact(0, true);
                            }}
                            style={{ marginRight: "20px" }}
                          >
                            Yes
                          </Button>
                          <Button
                            className="alrt-btn"
                            variant="outline-danger"
                            onClick={() => {
                              updateShowArray(0, false, true);
                              updateNewContact(0, false);
                            }}
                          >
                            No
                          </Button>
                        </div>
                      </Alert>
                    )}
                  {newContect?.first_name === "Select a contact" &&
                    showArray &&
                    showArray[0][0] === true && (
                      <Alert show={true}>
                        <div className="arlt-main-box">
                          <span className="alrt-inf0-icon">
                            <FontAwesomeIcon icon={faInfo} />
                          </span>
                          <span className="alrt-inf0-text">
                            Are you sure to clear contact ?
                          </span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Button
                            className="alrt-btn"
                            variant="outline-danger"
                            onClick={() => {
                              updateShowArray(0, false, true);
                              updateNewContact(0, true);
                            }}
                            style={{ marginRight: "20px" }}
                          >
                            Yes
                          </Button>
                          <Button
                            className="alrt-btn"
                            variant="outline-danger"
                            onClick={() => {
                              updateShowArray(0, false, true);
                              updateNewContact(0, false);
                            }}
                          >
                            No
                          </Button>
                        </div>
                      </Alert>
                    )}

                  <div className="model-drop-top">
                    <div style={{ width: "100%" }}>
                      <Form.Control
                        className="dropdown-addref-sec2"
                        value={
                          formData[0]?.contact && !useExistingContect[0]
                            ? `${formData[0]?.contact?.first_name} ${formData[0]?.contact?.last_name}`
                            : useExistingContect[0] && formData[0]?.exContact
                            ? `${formData[0]?.exContact?.first_name} ${formData[0]?.exContact?.last_name}`
                            : formData[0]?.exContact
                            ? `${formData[0]?.exContact?.first_name} ${formData[0]?.exContact?.last_name}`
                            : ""
                          // `${formData[0]?.exContact?.first_name} ${formData[0]?.exContact?.last_name}`
                        }
                        // onChange={(e) => handleFormControlChange(e.target.value)}
                        placeholder="Select a contact"
                      />
                      <Form.Select
                        name="contact"
                        className="dropdown-addref-sec3"
                        onChange={(event) => {
                          // handleInputChange(0, event);
                          const selectedContact = JSON.parse(
                            event.target.value
                          );
                          updateBooleanArray(0, selectedContact);
                        }}
                      >
                        {/* <option  style={{ fontWeight: 'bold' }}>Select Contact</option> */}
                        <option
                          value={JSON.stringify({
                            id: null,
                            first_name: "Select a contact",
                            last_name: "",
                          })}
                        >
                          Select a contact
                        </option>
                        <option
                          value={JSON.stringify({
                            id: formData[0]?.exContact?.id,
                            first_name: "Select a contact",
                            last_name: "",
                          })}
                          style={{ fontWeight: "bold" }}
                        >
                          Clear Contact
                        </option>

                        {contactData &&
                          contactData?.results?.map((contact) => (
                            <option
                              key={contact.id}
                              value={JSON.stringify(contact)}
                            >
                              {`${contact?.first_name} ${contact?.last_name}`}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                    <Button
                      className="backlink-button4"
                      variant="primary"
                      style={{ marginLeft: "15px" }}
                      onClick={() => handleEdit(formData[0])}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                    <Button
                      className="backlink-button6"
                      variant="primary"
                      onClick={() => {
                        handleAddNewContact();
                        setIndex(0);
                      }}
                    >
                      <FontAwesomeIcon icon={faUserPlus} />
                    </Button>
                  </div>
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="form-group">
                  <Form.Label className="model-form-label">
                    {" "}
                    Additional Details
                  </Form.Label>
                  <Form.Control
                    // required
                    as="textarea"
                    rows="2"
                    name="notes"
                    value={formData[0].notes}
                    onChange={(event) => handleInputChange(0, event)}
                  />
                </Form.Group>
              </div>
              <div className="mt-3 d-flex model-btn-flex">
                <Button
                  className="backlink-button1"
                  variant="primary"
                  type="submit"
                >
                  {loader ? "Saving.. " : "Save"}
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* add new contact modal  */}
      {showNewContactModal && (
        <Modal
          backdrop="static"
          show={showNewContactModal}
          onHide={handleCloseNewContactModal}
        >
          <Modal.Header closeButton>
            <div className="model-tittle-1">Add Contact</div>
          </Modal.Header>

          <Modal.Body>
            <Form method="POST" onSubmit={postContact}>
              <div className="add-from-link">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <Form.Group
                      className="form-group"
                      style={{ marginTop: "0px", marginBottom: "5px" }}
                    >
                      <Form.Label className="model-form-lable">
                        {" "}
                        First Name
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="first_name"
                        value={contactForm[0]?.first_name}
                        onChange={(event) => handleInputChangeContact(0, event)}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-12 col-lg-6">
                    <Form.Group
                      className="form-group"
                      style={{ marginTop: "0px", marginBottom: "5px" }}
                    >
                      <Form.Label className="model-form-lable">
                        {" "}
                        Last Name
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="last_name"
                        value={contactForm[0]?.last_name}
                        onChange={(event) => handleInputChangeContact(0, event)}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-lg-12">
                    <Form.Group
                      className="form-group"
                      style={{ marginTop: "0px", marginBottom: "5px" }}
                    >
                      <Form.Label className="model-form-lable">
                        {" "}
                        Email
                      </Form.Label>
                      <Form.Control
                        // required
                        type="email"
                        name="email"
                        value={contactForm[0]?.email}
                        onChange={(event) => handleInputChangeContact(0, event)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>

              {contactForm?.slice(1)?.map((contact, index) => (
                <div
                  style={{ marginTop: "10px" }}
                  key={index}
                  className="add-from-link"
                >
                  <div className="refrence-model-main">
                    <Button
                      className="close-btn ref-btn-model"
                      type="button"
                      onClick={() => handleRemoveContact(index + 1)}
                    >
                      <img src={close} alt="Your Image" />
                    </Button>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <Form.Group
                          className="form-group"
                          style={{ marginTop: "0px", marginBottom: "5px" }}
                        >
                          <Form.Label className="model-form-lable">
                            {" "}
                            First Name
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="first_name"
                            value={contact[index + 1]?.first_name}
                            onChange={(event) =>
                              handleInputChangeContact(index + 1, event)
                            }
                          />
                        </Form.Group>
                      </div>

                      <div className="col-12 col-lg-6">
                        <Form.Group
                          className="form-group"
                          style={{ marginTop: "0px", marginBottom: "5px" }}
                        >
                          <Form.Label className="model-form-lable">
                            {" "}
                            Last Name
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="last_name"
                            value={contact[index + 1]?.last_name}
                            onChange={(event) =>
                              handleInputChangeContact(index + 1, event)
                            }
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-lg-12">
                        <Form.Group
                          className="form-group"
                          style={{ marginTop: "0px", marginBottom: "5px" }}
                        >
                          <Form.Label className="model-form-lable">
                            {" "}
                            Email
                          </Form.Label>
                          <Form.Control
                            // required
                            type="email"
                            name="email"
                            value={contact[index + 1]?.email}
                            onChange={(event) =>
                              handleInputChangeContact(index + 1, event)
                            }
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="mt-3 d-flex model-btn-flex">
                <Button
                  className="backlink-button1"
                  variant="primary"
                  type="submit"
                >
                  {contactLoader ? "Saving.. " : "Save"}
                </Button>

                {/* <Button
                  className="backlink-button"
                  variant="primary"
                  onClick={handleCloseNewContactModal}
                >
                  Cancel
                </Button> */}

                <Button
                  className="backlink-button2 model-add-btn d-flex ms-auto align-items-center justify-content-center"
                  type="button"
                  onClick={handleAddMoreContacts}
                >
                  <FontAwesomeIcon className="btn-icon" icon={faPlusCircle} />{" "}
                  Add More
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
      {/* edit contact modal  */}
      {showEditModal && (
        <EditContact
          showEditModal={showEditModal}
          handleCloseEditModal={handleCloseEditModal}
          userForEdit={editUserData ? editUserData : contactData?.results[0]}
          fetchContact={fetchContact}
        />
      )}
    </>
  );
};

export default EditModel;
