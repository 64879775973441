import React, { useState } from "react";
import { Form, Button, Modal } from "@themesberg/react-bootstrap";
import apiService from "../../apiServices/apiService";
import { useNavigate } from "react-router-dom";

const FeedbackForm = () => {
    const navigate = useNavigate();
    const [feedbackData, setFeedbackData] = useState({
        feedback_type: "",
        subject: "",
        description: ""
    });

    const [submittedData, setSubmittedData] = useState(null);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFeedbackData({ ...feedbackData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await apiService.postFeedback(feedbackData);
            setSubmittedData({ ...feedbackData });
            setFeedbackData({
                feedback_type: "",
                subject: "",
                description: ""
            });
        } catch (error) {
            console.error("Error submitting feedback:", error);
        }
    };

    const [showPopup, setShowPopup] = useState(false);
    const handleClosePopup = () => {
        setShowPopup(false);
        navigate(-1);
    };

    return (
        <div className="feedback-pagee">
            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-4">
                <div className="login-text1">
                    <h2>Feedback</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="form-group">
                            <Form.Label>Feedback Type</Form.Label>
                            <Form.Select required name="feedback_type" onChange={handleInputChange} value={feedbackData.feedback_type}>
                                <option value="" disabled>Select Feedback Type</option>
                                <option value="Feedback">Feedback</option>
                                <option value="Suggestion">Suggestion</option>
                                <option value="Bug Report">Bug Report</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group style={{ marginTop: "5px" }} className="form-group">
                            <Form.Label> Subject</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="subject"
                                value={feedbackData.subject}
                                onChange={handleInputChange}
                                placeholder="Type Your Feedback Subject"
                            />
                        </Form.Group>

                        <Form.Group style={{ marginTop: "5px" }} className="form-group">
                            <Form.Label> Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="2"
                                name="description"
                                value={feedbackData.description}
                                onChange={handleInputChange}
                                placeholder="Type Your Feedback Description"
                            />
                        </Form.Group>
                        <div className="mt-3">
                            <Button
                                className="backlink-button1"
                                variant="primary"
                                type="submit"
                                onClick={() => setShowPopup(true)}
                            >
                                Submit Feedback
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
           
            <Modal as={Modal.Dialog} centered show={showPopup} onHide={handleClosePopup}>
                <Modal.Header closeButton>
                    <Modal.Title>Thanks for Submitting Your Feedback.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Feedback Type: {submittedData?.feedback_type}</p>
                    <p>Subject: {submittedData?.subject}</p>
                    <p>Description: {submittedData?.description}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePopup}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default FeedbackForm;
