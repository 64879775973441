import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faKey,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Col, Row, Form, Button, InputGroup, Card } from "@themesberg/react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Routess } from "../../routes";
import loginlogo from "../../assets/img/logo tracklink 1.png";
import loginimg from "../../assets/img/logoin.PNG";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import "./singin-up.css";
import axios from "axios";
import { baseUrl } from "../../config/config";

const Signup = () => {
  const history = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [about, setAbout] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);

  const handleCheckboxChange = () => {
    setAgreeTerms(!agreeTerms);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreeTerms) {
      toast.error("Please agree to the terms and conditions.");
      return;
    }
    setLoader(true);
    const data = {
      first_name: firstName,
      email: email,
      password: password,
      about: about,
      password_confirm: confirmPassword,
    };

    try {
      const url = `${baseUrl}/api/accounts/register/`;
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("Please check your email and verify.");
      setFirstName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setAbout("");
      setLoader(false);
      history("/");
      const res = response.data;
      setMsg(res.message);
    } catch (error) {
      setError(error.message);
      if (error?.response?.data) {
        const errorData = error.response.data;
        if (errorData.email) {
          toast.error(errorData.email.join(" "));
        }
        if (errorData.password) {
          toast.error(errorData.password.join(" "));
        }
        if (errorData.password_confirm) {
          toast.error(errorData.password_confirm.join(" "));
        }
        if (errorData.user_name) {
          toast.error(errorData.user_name.join(" "));
        }
      }
      setLoader(false);
    }
  };

  return (
    <main className="log-main">
      <section className="d-flex justify-content-center align-items-center my-5 mt-lg-3 mb-lg-3">
        <Row className="justify-content-center form-bg-image">
          <Col xs={12} md={6}>
            <div className="login-img">
              <img className="login-logo" src={loginlogo} alt="Your Image" />
              <img className="login-img-main" src={loginimg} alt="Your Image" />
            </div>
          </Col>

          <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
            <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
              <div className="mb-4 mt-md-0">
                <div className="login-text1">
                  <p>Create an account</p>
                  <h2>Track Link Pro Account</h2>
                </div>
              </div>
              <Form className="mt-4" onSubmit={handleSubmit}>
                <Form.Group id="name" className="mb-3">
                  <InputGroup>
                    <InputGroup.Text className="input-grp1">
                      <FontAwesomeIcon style={{ color: "black" }} icon={faUser} />
                    </InputGroup.Text>
                    <Form.Control
                      className="input-grp"
                      autoFocus
                      required
                      type="text"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group id="email" className="mb-3">
                  <InputGroup>
                    <InputGroup.Text className="input-grp1">
                      <FontAwesomeIcon style={{ color: "black" }} icon={faEnvelope} />
                    </InputGroup.Text>
                    <Form.Control
                      className="input-grp"
                      autoFocus
                      required
                      type="email"
                      placeholder="example@company.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group id="password" className="mb-3">
                  <InputGroup>
                    <InputGroup.Text className="input-grp1">
                      <FontAwesomeIcon style={{ color: "black" }} icon={faKey} />
                    </InputGroup.Text>
                    <Form.Control
                      id="password"
                      className="input-grp"
                      required
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group id="confirmPassword" className="mb-3">
                  <InputGroup>
                    <InputGroup.Text className="input-grp1">
                      <FontAwesomeIcon style={{ color: "black" }} icon={faKey} />
                    </InputGroup.Text>
                    <Form.Control
                      className="input-grp"
                      required
                      type="password"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    style={{ marginBottom: "10px" }}
                    className="login-check-box"
                    type="checkbox"
                    id="terms"
                    label=" I agree to the terms and conditions"
                    checked={agreeTerms}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>

                <Button className="login-btn" variant="primary" type="submit">
                  {loader ? "Submitting..." : "Register Now"}
                </Button>
              </Form>

              <div style={{ marginTop: "20px" }} className="or">
                <div className="or-line"></div>
                <div className="or-or">OR</div>
                <div className="or-line"></div>
              </div>

              <div className="d-flex justify-content-center align-items-center mt-4">
                <span className="fw-normal">
                  Already have an account?
                  <Card.Link
                    as={Link}
                    to={Routess.Signin.path}
                    className="fw-bold"
                  >
                    {` Login here `}
                  </Card.Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <Footer />
    </main>
  );
};

export default Signup;
