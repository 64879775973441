import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';

// Initial State
const initialState = {
  users: [],
  datas:[],
  images:[]
}

// Create Context
export const GlobalContext = createContext(initialState);

// Provider Component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions


  const addUser = (user) => {
    dispatch({
      type: 'ADD_USER',
      payload: user
    })
  }

  
  const addData = (data) => {
    dispatch({
      type: 'ADD_DATA',
      payload: data
    })
  }
  const addImages = (data) => {
    dispatch({
      type: 'ADD_IMAGES',
      payload: data
    })
  }
  return (
    <GlobalContext.Provider value={{
      users: state.users,
      datas: state.datas,
      images: state.images,
      addUser,
      addData,
      addImages
    }}>
      {children}
    </GlobalContext.Provider>
  )
}