import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Routess } from "../../routes";
import {
  faEnvelope,
  faUnlockAlt,
  faKey,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useNavigate } from "react-router-dom";

// import {login} from "../../actions/index"
import { login } from "../../reducers/loginSlice";
import loginlogo from "../../assets/img/logo tracklink 1.png";
import loginimg from "../../assets/img/logoin.PNG";
import googleimg from "../../assets/img/search 1.svg";
import metaeimg from "../../assets/img/Vector.svg";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../config/config";
import { toast } from "react-toastify";
import "./singin-up.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
export default () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const user_id = params.get("user_id");
  const timestamp = params.get("timestamp");
  const signature = params.get("signature");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const loginUser = async (e) => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      toast.error("Input fields is required");
      return;
    }
    setLoader(true);
    const data = {
      user_id: user_id,
      timestamp: timestamp,
      signature: signature,
      password: password,
    };
    if (password === confirmPassword) {
      try {
        const url = `${baseUrl}/api/accounts/reset-password/`;
        const { data: res } = await axios.post(url, data);
        setLoader(false);
        toast.success("Reset password Successfully.");
        setTimeout(() => {
          history("/");
        }, 2000);
      } catch (error) {
        setLoader(false);
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          toast.error("Invaild URL");
        }
      }
    } else {
      toast.error("Password miss match");
    }
  };

  return (
    <div className="chnage-pw">
      <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-4">
        <div className="login-text1">
          <p>Reset your Password</p>
          {/* <h2>Track Link Pro Account</h2> */}
        </div>
        <Form className="mt-4" method="POST">
          <Form.Group>
            <Form.Group id="password" className="mb-3">
              <InputGroup>
                <InputGroup.Text className="input-grp1">
                  <FontAwesomeIcon style={{ color: "black" }} icon={faKey} />
                </InputGroup.Text>
                <Form.Control
                  className="input-grp"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
              </InputGroup>
            </Form.Group>
          </Form.Group>

          <Form.Group>
            <Form.Group id="confirmPassword" className="mb-3">
              <InputGroup>
                <InputGroup.Text className="input-grp1">
                  <FontAwesomeIcon style={{ color: "black" }} icon={faKey} />
                </InputGroup.Text>
                <Form.Control
                  className="input-grp"
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  required
                />
              </InputGroup>
            </Form.Group>
          </Form.Group>

          <Button type="submit" onClick={loginUser} className="login-btn">
            {!loader ? "Reset Password" : "Resetting.."}
          </Button>
        </Form>
      </div>
    </div>
  );
};
