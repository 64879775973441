import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Dropdown,
  Image,
  Nav,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faSignOutAlt,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import Down from "../assets/img/chevron-down.svg";
import Logoimg from "../assets/img/logo tracklink 1.png";
import { toast } from "react-toastify";
import { navigate } from "react-router-dom";
import apiService from "../apiServices/apiService";
import useDelayedExecution from "../config/useDelayedExecution";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
const Sidebar = (props) => {
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const history = useNavigate();

  const getData = async () => {
    setIsLoading(true);
    try {
      const data = await apiService.fetchUserData();
      setUserData(data);
    } catch (error) {
      console.log("error during calling data", error);
    }
    setIsLoading(false);
  };

  useDelayedExecution(() => {
    getData();
  }, 1000);

  const logoutUser = async () => {
    try {
      await apiService.logout();
      // toast.error("Logout Successfully");
      await localStorage.removeItem("trp_token");
      setTimeout(() => {
        history("/");
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error occurred during logout", error);
    }
  };

  return (
    <Navbar expanded className="navbar">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div>
            <Link to="/">
              <Image className="logoimg" src={Logoimg} />
            </Link>
          </div>
          <Nav className="align-items-center second-nav">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  {/* <Image
                    style={{ marginRight: "15px" }}
                    src={Avtar}
                    className="user-avatar md-avatar rounded-circle"
                  /> */}
                  <div className="media-body ms-2 align-items-center d-lg-block">
                    <span className="users-name">Welcome, </span>
                    {isLoading ? (
                      <span className="usera-name-bold">
                        <Skeleton height={20} width={`100%`} />
                      </span>
                    ) : (
                      <span className="usera-name-bold">
                        {userData?.first_name}
                      </span>
                    )}
                  </div>
                  <Image
                    style={{ marginRight: "15px" }}
                    className="d-lg-block"
                    src={Down}
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right">
                {/* <Dropdown.Item className="fw-bold">
                  <button className="top-drop-menu-btn" type="submit">
                    <FontAwesomeIcon icon={faAddressCard} className="me-2" /> Profile
                  </button>
                </Dropdown.Item> */}
                {/* <Dropdown.Divider /> */}
                <Dropdown.Item className="fw-bold">
                  <Link to="/change-password">
                    <button
                      // onClick={}
                      class="top-drop-menu-btn"
                      type="submit"
                    >
                      <FontAwesomeIcon icon={faUnlockAlt} className="me-2" />{" "}
                      Change Password
                    </button>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="fw-bold">
                  <button
                    onClick={logoutUser}
                    className="top-drop-menu-btn"
                    type="submit"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};

export default Sidebar;
