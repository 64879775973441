import React from "react";
import moment from "moment-timezone";
import {
  Row,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
  Image,
  Button,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faDownload,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import BS5Logo from "../assets/img/technologies/bootstrap-5-logo.svg";
import ReactLogo from "../assets/img/technologies/react-logo.svg";
import LaravelLogo from "../assets/img/technologies/laravel-logo.svg";
// import GitHubButton from 'react-github-btn';
import { Link } from "react-router-dom";
import { Routes } from "../routes";

export default (props) => {
  const currentYear = moment().get("year");
  const showSettings = props.showSettings;

  const toggleSettings = (toggle) => {
    props.toggleSettings(toggle);
  };

  return (
    <>
      <footer className="dashboard-footer">
        <div className="footer-content">
          © 2024 TrackLink Pro. All Rights Reserved<span>l</span>Privacy Policy
          <span>l</span>
          <Link to="/feedback-page">Feedback </Link>
        </div>
      </footer>
    </>
  );
};
