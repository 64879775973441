import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faSyncAlt, faPlusCircle, faEnvelope, faUnlockAlt, faKey, faUser, } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Table, Image, Container, Button, OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap';
import GreenBadges from "../../assets/img/green-badge 1.png";
import RedBadges from "../../assets/img/red-badge 1.png";
import upload from "../../assets/img/shape.svg";
import download from "../../assets/img/shape 1.svg";
import info from "../../assets/img/info.svg";
import filter from "../../assets/img/arrows.svg";
import check from "../../assets/img/Check_fill.png";
import dell from "../../assets/img/Dell_fill.png";
import detail from "../../assets/img/View_light.png";
import trash from "../../assets/img/Trash_light.png";




// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (

    <>
      <section className="main-dashboard">
        <div className="top-button">
          <Button
            type="submit"
            // onClick={}
            className="backlink-button1"
          >
            <img className="img-icon" src={download} alt="Your Image" />  Download Sample
          </Button>
        </div>

        <div className="add-link-box">

          <div className="row">
            <div className="col-12 col-lg-6 col-sm-12">
              <h2>Add Link</h2>
              <div className="rediobtn-form">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="exampleRadio" id="exampleRadio1" />
                  <label className="form-check-label" htmlFor="exampleRadio1">
                  Owned
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="exampleRadio" id="exampleRadio1" />
                  <label className="form-check-label" htmlFor="exampleRadio1">
                  Referenced
                  </label>
                </div>
              </div>

            </div>

            <div className="col-12 col-lg-6 col-sm-12">

            </div>

          </div>

        </div>
      </section>


    </>


  );
};