
import React from 'react';

class LinkInNewTab extends React.Component {
  handleClick = () => {
    const { url } = this.props;
    // Open the URL in a new tab
    window.open(url, '_blank');
  }

  render() {
    const { text } = this.props;
    return (
      <span className="link-col" onClick={this.handleClick} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
        {text}
      </span>
    );
  }
}

export default LinkInNewTab;

