import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Table,
  Modal,
  Popover,
  Image,
  Container,
  Button,
  OverlayTrigger,
  Tooltip,
  Form,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-bootstrap/Alert";
import {
  faPlusCircle,
  faUserPlus,
  faPen,
  faInfo,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import close from "../../assets/img/Vector.svg";
import apiService from "../../apiServices/apiService";
import { baseUrl } from "../../config/config";
import { EditContact } from "./EditContact";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const AddRefrenceModel = ({
  show,
  handleClose,
  refLinkData,
  fetchBacklinks,
  websiteName,
}) => {
  const [refLink, setRefLink] = useState({});
  const [contactLoader, setContactLoader] = useState(false);
  const [contactData, setContactData] = useState(null);
  //1 cretate state for error massage
  const [errorMsg1, setErrorMag1] = useState([]);
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState([
    {
      owned_url: "",
      referenced_url: null,
      referenced_url_name: "",
      contact: null,
      anchor_text: "",
      notes: "",
      exContact: null,
    },
  ]);
  const [useExistingContect, setExContact] = useState([false]);
  const [useNewContect, setNewContact] = useState([false]);
  const [loader, setLoader] = useState(false);
  const [showNewContactModal, setShowNewContactModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editUserData, setEdituserData] = useState({});
  const [editContectIndex, setEditContectIndex] = useState("");
  const [refUrl, setRefUrl] = useState([]);
  const [showAllSite, setShowAllSite] = useState([false]);
  const [customName, setCustomName] = useState("");
  // const [errorMsg, setErrorMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState([]);
  const [indexForContact, setIndex] = useState(null);
  // contact form submit
  const [contactForm, setContactForm] = useState([
    { first_name: "", last_name: "", email: "" },
  ]);
  const handleInputChangeContact = (index, event) => {
    const newFormData = [...contactForm];
    newFormData[index][event.target.name] = event.target.value;
    setContactForm(newFormData);
  };
  const handleAddMoreContacts = () => {
    setContactForm([
      ...contactForm,
      { first_name: "", last_name: "", email: "" },
    ]);
  };
  const handleRemoveContact = (index) => {
    const newFormData = [...contactForm];
    newFormData.splice(index, 1);
    setContactForm(newFormData);
  };
  // add contact
  // const postContact = async (event) => {
  //   event.preventDefault();
  //   setContactLoader(true);
  //   try {
  //     const postResponse = await apiService.postContactdata(contactForm);
  //     if (postResponse) {
  //       // console.log("Data successfully sent to the server");
  //       setContactForm([{ first_name: "", last_name: "", email: "" }]);
  //     } else {
  //       console.error("Failed to send data to the server");
  //     }
  //   } catch (error) {
  //     console.error("Error occurred while sending data to the server", error);
  //   }
  //   setTimeout(() => {
  //     handleCloseNewContactModal();
  //   }, 2000);
  //   fetchContact();
  //   setContactLoader(false);
  // };
  const postContact = async (e) => {
    e.preventDefault();
    setContactLoader(true);
    const data = await apiService.postContactdata(contactForm);
    if (data?.data?.errors?.length === 0) {
      setTimeout(() => {
        setContactForm([{ first_name: "", last_name: "", email: "" }]);
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData]; // Copy the current state array
          updatedFormData[indexForContact] = {
            ...updatedFormData[indexForContact],
            contact:
              data?.data?.successful_contacts &&
              data?.data?.successful_contacts[0],
          }; // Update contact field
          return updatedFormData; // Update the state with the new array
        });
        handleCloseNewContactModal();
        // fetchNiche();
        setIndex(null);
      }, 3000);
    } else {
      // this is the logic for remove added data form tha form aaray
      const indices = data?.response?.data?.errors?.map((error) => error.index);
      const indicesToKeep = indices || [];
      indicesToKeep?.sort((a, b) => b - a);
      const filteredFormData = contactForm.filter((item, index) =>
        indicesToKeep.includes(index)
      );
      setTimeout(() => {
        setContactForm(filteredFormData);
      }, 1000);
    }
    setContactLoader(false);
    fetchContact();
  };
  //get contact
  const fetchContact = async () => {
    try {
      const data = await apiService.getContact();
      const refUrlData = await apiService.getRefUrl();
      setContactData(data);
      setRefUrl(refUrlData);
    } catch (error) {
      console.error("Error fetching contact data:", error);
    }
  };
  // fet refURl auto suggestion
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    if (searchQuery) {
      fetRefUrl();
    } else {
      setSuggestions([]);
    }
  }, [searchQuery]);
  const fetRefUrl = async () => {
    try {
      const refUrlData = await apiService.getRefUrl(searchQuery);
      setRefUrl(refUrlData);
      setSuggestions(refUrlData);
    } catch (error) {
      console.error("Error fetching refUrl data:", error);
    }
  };

  useEffect(() => {
    fetchContact();
    fetRefUrl();
    setFormData((prevFormData) => [
      {
        referenced_url: null,
        contact: null,
        owned_url: refLinkData,
        notes: "",
        anchor_text: "",
      },
      ...prevFormData?.slice(1),
    ]);
  }, [refLinkData]);

  const handleAddMore = () => {
    setFormData([
      ...formData,
      {
        referenced_url: "",
        contact: "",
        owned_url: refLinkData,
        notes: "",
        anchor_text: "",
      },
    ]);
  };

  const handleRemove = (index) => {
    const links = [...formData];
    links.splice(index, 1);
    setFormData(links);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    console.log("value", event);
    setFormData((prevContactForm) => {
      const updatedContactForm = [...prevContactForm];
      if (name === "referenced_url") {
        const selectedContact = JSON.parse(value);
        updatedContactForm[index] = {
          ...updatedContactForm[index],
          [name]: selectedContact,
        };
        if (selectedContact?.contact) {
          const newArray = [...useExistingContect];
          newArray[index] = true;
          setExContact(newArray);
          updatedContactForm[index].exContact = contactData?.results?.find(
            (itam) => itam?.id === selectedContact?.contact
          );
        }
      } else if (name === "contact") {
        const selectedContact = JSON.parse(value);
        updatedContactForm[index] = {
          ...updatedContactForm[index],
          [name]: selectedContact,
        };
        updatedContactForm[index] = {
          ...updatedContactForm[index],
          ["exContact"]: null,
        };

        if (selectedContact?.contact) {
          const newArray = [...useNewContect];
          newArray[index] = true;
          // console.log("new array", newArray)
          setNewContact(newArray);
          updatedContactForm[index].exContact = contactData?.results?.find(
            (itam) => itam?.id === selectedContact?.contact
          );
        }
      } else {
        updatedContactForm[index] = {
          ...updatedContactForm[index],
          [name]: value,
        };

        // Clear the other field based on conditions
        if (name === "referenced_url") {
          updatedContactForm[index].referenced_url_name = "";
        } else if (name === "referenced_url_name") {
          updatedContactForm[index].referenced_url = null;
        }
      }

      return updatedContactForm;
    });
  };

  const postRefrenceLink = async (e) => {
    e.preventDefault();
    const updatedData = formData.map((item, index) => ({
      referenced_url: item?.referenced_url?.id
        ? item?.referenced_url?.id
        : null,
      owned_url: item?.owned_url,
      notes: item?.notes,
      anchor_text: item?.anchor_text,
      UI_only: {
        referenced_url_name: item.referenced_url_name
          ? item.referenced_url_name
          : "",
        contact: useExistingContect[index]
          ? item?.exContact?.id
          : useNewContect[index]
          ? item?.contact?.id
          : item?.contact?.id,
      },
    }));
    setLoader(true);
    try {
      const data = await apiService.postTrackLinkRefrence(updatedData);
      if (data?.data?.errors?.length === 0) {
        setTimeout(() => {
          setFormData([
            {
              referenced_url: null,
              referenced_url_name: "",
              contact: null,
              anchor_text: "",
              notes: "",
              owned_url: refLinkData,
              exContact: "",
            },
          ]);
        }, 1000);
        setErrorMsg(data);
        setLoader(false);
      } else {
        // this is the logic for remove added data form tha form aaray
        setErrorMsg(data?.response?.data?.errors);
        const indices = data?.response?.data?.errors?.map(
          (error) => error.index
        );
        const indicesToKeep = indices || [];
        indicesToKeep?.sort((a, b) => b - a);
        const filteredFormData = formData.filter((item, index) =>
          indicesToKeep.includes(index)
        );
        setTimeout(() => {
          setFormData(filteredFormData);
        }, 1000);

        setTimeout(() => {
          setFormData(filteredFormData);
        }, 1000);
      }
      setNewContact(0, false);
      updateExContact(0, false);
      updateShowArray(0, false);
      fetRefUrl();
      fetchBacklinks();
      setLoader(false);
    } catch (error) {
      console.log("Error during post:", error);
      setLoader(false);
    }
  };

  // this is use for the oprn and close the modal
  const handleAddNewContact = () => {
    setShowNewContactModal(true);
  };

  const handleCloseNewContactModal = () => {
    setShowNewContactModal(false);
  };

  const handleCloseEditModal = async () => {
    setShowEditModal(false);
    if (formData[editContectIndex]?.contact?.id) {
      const data = await apiService.getContactById(
        formData[editContectIndex]?.contact?.id
      );
      if (data) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[editContectIndex] = {
            ...updatedFormData[editContectIndex],
            contact: data,
          };
          return updatedFormData;
        });
      }
    } else {
      const data = await apiService.getContactById(
        formData[editContectIndex]?.exContact?.id
      );
      if (data) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[editContectIndex] = {
            ...updatedFormData[editContectIndex],
            contact: data,
          };
          return updatedFormData;
        });
      }
    }
  };

  // handle edit contact
  const handleEdit = (index, data) => {
    setEditContectIndex(index);
    setEdituserData(data.exContact ? data.exContact : data?.contact);
    setShowEditModal(true);
  };

  const [showArray, setShowArray] = useState([false, false]);

  const updateBooleanArray = (index, value) => {
    const currentContact = formData[index]?.exContact;
    const newArray = [...showArray];
    newArray[index] =
      currentContact && currentContact?.id === value?.id
        ? [false, false]
        : currentContact && currentContact?.id !== value?.id
        ? [true, false]
        : [true, false];
    setShowArray(newArray);
  };

  const updateShowArray = (index, value, value2) => {
    const newArray = [...showArray];
    newArray[index] = [value, value2];
    setShowArray(newArray);
  };
  const updateExContact = (index, value) => {
    const newArray = [...useExistingContect];
    newArray[index] = value;
    setExContact(newArray);
  };
  const updateNewContact = (index, value) => {
    if (index >= 0 && index < useNewContect.length) {
      const newArray = [...useNewContect];
      newArray[index] = value;
      setNewContact(newArray);
    } else {
      console.error("Invalid index provided:", index);
    }
  };

  const handleCloseModal = () => {
    setFormData([
      {
        owned_url: refLinkData,
        referenced_url: null,
        referenced_url_name: "",
        contact: null,
        anchor_text: "",
        notes: "",
        exContact: null,
      },
    ]);
    setNewContact(0, false);
    updateExContact(0, false);
    updateShowArray(0, false);
    handleClose();

    // window.location.reload();
  };

  const handleShowAllSite = (index) => {
    const updatedState = [...showAllSite];
    updatedState[index] = !updatedState[index];
    setShowAllSite(updatedState);
  };
  console.log("formData", formData);
  return (
    <>
      <Modal
        show={show && !showNewContactModal && !showEditModal}
        onHide={handleCloseModal}
        backdrop="static"
      >
        <Modal.Header closeButton className="model-header-set">
          <div className="model-top-sec">
            <div className="model-tittle-1" style={{ fontSize: "20px" }}>
              Add Expected Backlink Sources
            </div>
            <div
              style={{ fontSize: "20px", fontWeight: "700", color: "#1118b9" }}
            >
              {show && show.rowData && show.rowData.name}
            </div>
            <div
              style={{ fontSize: "20px", fontWeight: "700", color: "#1118b9" }}
            >
              {websiteName && websiteName}
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form method="POST" onSubmit={postRefrenceLink}>
            <div className="add-from-link">
              {errorMsg && errorMsg[0] && errorMsg[0]?.errors && (
                <>
                  {errorMsg[0]?.errors?.name && (
                    <div style={{ marginBottom: "5px" }}>
                      <span className="error-box">
                        {" "}
                        <FontAwesomeIcon icon={faTriangleExclamation} />{" "}
                        {errorMsg[0]?.errors?.name[0]}
                      </span>
                    </div>
                  )}
                  {errorMsg[0]?.errors &&
                    errorMsg[0]?.errors?.non_field_errors && (
                      <div style={{ marginBottom: "5px" }}>
                        <span className="error-box">
                          {" "}
                          <FontAwesomeIcon icon={faTriangleExclamation} />{" "}
                          {errorMsg[0]?.errors?.non_field_errors[0]}
                        </span>
                      </div>
                    )}
                  {!errorMsg[0]?.errors?.non_field_errors &&
                    errorMsg[0]?.errors !==
                      "UI_only.referenced_url_name is missing" && (
                      <div style={{ marginBottom: "5px" }}>
                        <span className="error-box">
                          {" "}
                          <FontAwesomeIcon icon={faTriangleExclamation} />{" "}
                          {errorMsg[0]?.errors}
                        </span>
                      </div>
                    )}
                </>
              )}
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Form.Group
                    className="form-group"
                    style={{
                      marginTop: "0px",
                      marginBottom: "5px",
                      height: "88px",
                    }}
                  >
                    <Form.Label className="model-form-label">
                      Backlink Source <span style={{ color: "red" }}>*</span>
                      {errorMsg &&
                        errorMsg[0] &&
                        errorMsg[0]?.errors ===
                        "UI_only.referenced_url_name is missing" && (
                          <span className="text-danger">
                            {" "}
                            <FontAwesomeIcon
                              icon={faTriangleExclamation}
                            />{" "}
                            This is required
                          </span>
                        )}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="referenced_url_name"
                      className="dropdown-addref-sec1"
                      value={
                        formData[0]?.referenced_url
                          ? formData[0]?.referenced_url?.name
                          : formData[0]?.referenced_url_name
                      }
                      // value={formData[0]?.referenced_url_name}
                      onChange={(event) => {
                        handleInputChange(0, event);
                      }}
                      placeholder="Enter or select a website"
                    />
                    <Form.Select
                      name="referenced_url"
                      // value={formData[0].referenced_url}
                      className="dropdown-addref-sec"
                      onChange={(event) => {
                        if (event.target.value == "callFunctionOption") {
                          handleShowAllSite(0);
                        } else {
                          handleInputChange(0, event);
                          // handleShowAllSite(0)
                        }
                      }}
                    >
                      {/* {formData[0]?.contact?.id && (
                        <option value="callFunctionOption">Select a site</option>
                      )} */}
                     
                       <option value="callFunctionOption">Select a site</option>
                      {formData[0]?.contact?.id && !showAllSite[0]
                        ? refUrl
                            .filter(
                              (item) =>
                                item?.contact === formData[0]?.contact?.id
                            )
                            ?.map((filteredItem, index) => (
                              <option
                                key={index}
                                value={JSON.stringify(filteredItem)}
                                data-id={filteredItem?.id}
                              >
                                {filteredItem?.name}
                              </option>
                            ))
                        : refUrl?.map((item, index) => (
                            <option
                              key={index}
                              value={JSON.stringify(item)}
                              data-id={item?.id}
                            >
                              {item?.name}
                            </option>
                          ))}
                           {formData[0]?.contact?.id && !showAllSite[0] && (
                        <option value="callFunctionOption">
                          Show All Website
                        </option>
                      )}
                      {/* {formData[0]?.contact?.id && refUrl.filter((item) => item?.contact === (formData[0]?.contact)?.id)?.length===0 && <option>Show All Website</option>} */}
                    </Form.Select>
                  </Form.Group>
                </div>

                <div className="col-12 col-lg-6">
                  <Form.Group
                    className="form-group"
                    style={{ marginTop: "0px", marginBottom: "5px" }}
                  >
                    <Form.Label className="model-form-label">
                      {" "}
                      Desired Anchor Text
                    </Form.Label>
                    <Form.Control
                      // required
                      type="text"
                      name="anchor_text"
                      value={formData[0]?.anchor_text}
                      onChange={(event) => handleInputChange(0, event)}
                      placeholder="Enter anchor text"
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <Form.Group
                  className="form-group"
                  style={{
                    marginTop: "0px",
                    marginBottom: "5px",
                    height: "88px",
                  }}
                >
                  <Form.Label className="model-form-label">Contact</Form.Label>
                  {useExistingContect &&
                    useExistingContect[0] &&
                    showArray &&
                    showArray[0][0] === true ? (
                    <Alert show={showArray && showArray[0][0] === true}>
                      <div className="arlt-main-box">
                        <span className="alrt-inf0-icon">
                          <FontAwesomeIcon icon={faInfo} />
                        </span>
                        {showArray &&
                          showArray[0][1] === false &&
                          useExistingContect &&
                          useExistingContect[0] ? (
                          <span className="alrt-inf0-text">
                            Do you want to change the contact for this site to{" "}
                            {`${formData[0]?.contact?.first_name} ${formData[0]?.contact?.last_name}`}{" "}
                            ?
                          </span>
                        ) : showArray && showArray[0][1] === true ? (
                          <span className="alrt-inf0-text">
                            Are you sure to clear contact ?
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Button
                          className="alrt-btn"
                          variant="outline-danger"
                          onClick={() => {
                            updateShowArray(0, false, true);
                            updateNewContact(0, true);
                            updateExContact(0, false);
                            setFormData((prevFormData) => {
                              const updatedFormData = [...prevFormData]; 
                              updatedFormData[0] = {
                                ...updatedFormData[0],
                                exContact:null
                              }; 
                              return updatedFormData; 
                            });
                          }}
                          style={{ marginRight: "20px" }}
                        >
                          Yes
                        </Button>
                        <Button
                          className="alrt-btn"
                          variant="outline-danger"
                          onClick={() => {
                            updateShowArray(0, false, true);
                            updateNewContact(0, false);
                            updateExContact(0, true);
                          }}
                        >
                          No
                        </Button>
                      </div>
                    </Alert>
                  ) : (
                    ""
                  )}
                  {formData[0]?.contact?.first_name === "Select a contact" &&
                    showArray &&
                    showArray[0][0] === true && (
                      <Alert show={true}>
                        <div className="arlt-main-box">
                          <span className="alrt-inf0-icon">
                            <FontAwesomeIcon icon={faInfo} />
                          </span>
                          <span className="alrt-inf0-text">
                            Are you sure to clear contact ?
                          </span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Button
                            className="alrt-btn"
                            variant="outline-danger"
                            onClick={() => {
                              updateShowArray(0, false, true);
                              updateNewContact(0, true);
                              updateExContact(0, false);
                            }}
                            style={{ marginRight: "20px" }}
                          >
                            Yes
                          </Button>
                          <Button
                            className="alrt-btn"
                            variant="outline-danger"
                            onClick={() => {
                              updateShowArray(0, false, true);
                              updateNewContact(0, false);
                              updateExContact(0, true);
                            }}
                          >
                            No
                          </Button>
                        </div>
                      </Alert>
                    )}

                  <div className="model-drop-top">
                    <div style={{ width: "100%" }}>
                      <Form.Control
                        className="dropdown-addref-sec2"
                        value={
                          formData[0]?.contact && !useExistingContect[0]
                            ? `${formData[0]?.contact?.first_name} ${formData[0]?.contact?.last_name}`
                            : useExistingContect[0] && formData[0]?.exContact
                              ? `${formData[0]?.exContact?.first_name} ${formData[0]?.exContact?.last_name}`
                              : formData[0]?.exContact
                                ? `${formData[0]?.exContact?.first_name} ${formData[0]?.exContact?.last_name}`
                                : ""
                        }
                        // onChange={(e) => handleFormControlChange(e.target.value)}
                        placeholder="Select a contact"
                      />
                      <Form.Select
                        name="contact"
                        className="dropdown-addref-sec3"
                        onChange={(event) => {
                          handleInputChange(0, event);
                          const selectedContact = JSON.parse(
                            event.target.value
                          );
                          updateBooleanArray(0, selectedContact);
                          handleShowAllSite(0);
                        }}
                      >
                        {/* <option  style={{ fontWeight: 'bold' }}>Select Contact</option> */}
                        <option disabled
                          value={JSON.stringify({
                            id: null,
                            first_name: "Select a contact",
                            last_name: "",
                          })}
                        >
                          Select a contact
                        </option>
                        <option
                          value={JSON.stringify({
                            id: formData[0]?.exContact?.id,
                            first_name: "Select a contact",
                            last_name: "",
                          })}
                          style={{ fontWeight: "bold" }}
                        >
                          Clear Contact
                        </option>

                        {contactData &&
                          contactData?.results?.map((contact) => (
                            <option
                              key={contact?.id}
                              value={JSON.stringify(contact)}
                            >
                              {`${contact?.first_name} ${contact?.last_name}`}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                    <Button
                      className="backlink-button4"
                      variant="primary"
                      style={{ marginLeft: "15px" }}
                      onClick={() => handleEdit(0, formData[0])}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                    <Button
                      className="backlink-button6"
                      variant="primary"
                      onClick={() => {
                        handleAddNewContact();
                        setIndex(0);
                      }}
                    >
                      <FontAwesomeIcon icon={faUserPlus} />
                    </Button>
                  </div>
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="form-group">
                  <Form.Label className="model-form-label">
                    {" "}
                    Additional Details
                  </Form.Label>
                  <Form.Control
                    // required
                    as="textarea"
                    rows="2"
                    name="notes"
                    value={formData[0]?.notes}
                    onChange={(event) => handleInputChange(0, event)}
                  />
                </Form.Group>
              </div>
            </div>
            {/* for add more section data  */}
            {formData?.slice(1)?.map((contact, index) => (
              <>
                <div
                  style={{ marginTop: "10px" }}
                  key={index}
                  className="add-from-link"
                >
                  <div className="refrence-model-main">
                    <Button
                      className="close-btn ref-btn-model"
                      type="button"
                      onClick={() => handleRemove(index + 1)}
                    >
                      <img src={close} alt="Your Image" />
                    </Button>

                    <div className="row">
                      {errorMsg &&
                        errorMsg[index + 1] &&
                        errorMsg[index + 1]?.errors && (
                          <>
                            {errorMsg[index + 1]?.errors?.name && (
                              <div style={{ marginBottom: "5px" }}>
                                <span className="error-box">
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={faTriangleExclamation}
                                  />{" "}
                                  {errorMsg[index + 1]?.errors?.name[0]}
                                </span>
                              </div>
                            )}
                            {errorMsg[index + 1]?.errors &&
                              errorMsg[index + 1]?.errors?.non_field_errors && (
                                <div style={{ marginBottom: "5px" }}>
                                  <span className="error-box">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faTriangleExclamation}
                                    />{" "}
                                    {
                                      errorMsg[index + 1]?.errors
                                        ?.non_field_errors[0]
                                    }
                                  </span>
                                </div>
                              )}
                            {!errorMsg[index + 1]?.errors?.non_field_errors &&
                              errorMsg[index + 1]?.errors !==
                                "UI_only.referenced_url_name is missing" && (
                                <div style={{ marginBottom: "5px" }}>
                                  <span className="error-box">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faTriangleExclamation}
                                    />{" "}
                                    {errorMsg[index + 1]?.errors}
                                  </span>
                                </div>
                              )}
                          </>
                        )}
                      <div className="col-12 col-lg-6">
                        <Form.Group
                          className="form-group"
                          style={{
                            marginTop: "0px",
                            marginBottom: "5px",
                            height: "88px",
                          }}
                        >
                          <Form.Label className="model-form-label">
                            Backlink Source{" "}
                            <span style={{ color: "red" }}>*</span>
                            {errorMsg &&
                              errorMsg[index + 1] &&
                              errorMsg[index + 1]?.errors ===
                              "UI_only.referenced_url_name is missing" && (
                                <span className="text-danger">
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={faTriangleExclamation}
                                  />{" "}
                                  This is required
                                </span>
                              )}
                          </Form.Label>

                          <Form.Control
                            type="text"
                            name="referenced_url_name"
                            className="dropdown-addref-sec1"
                            value={
                              formData[index + 1]?.referenced_url
                                ? formData[index + 1]?.referenced_url?.name
                                : formData[index + 1]?.referenced_url_name
                            }
                            // value={formData[0]?.referenced_url_name}
                            onChange={(event) => {
                              handleInputChange(index + 1, event);
                            }}
                            placeholder="Enter or select a website"
                          />
                          <Form.Select
                            name="referenced_url"
                            // value={formData[0].referenced_url}
                            className="dropdown-addref-sec"
                            onChange={(event) => {
                              if (event.target.value == "callFunctionOption") {
                                handleShowAllSite(index + 1);
                              } else {
                                handleInputChange(index + 1, event);
                                // handleShowAllSite(index+1)
                              }
                            }}
                          >
                            {formData[index + 1]?.contact?.id && (
                              <option value="callFunctionOption">
                                Select Site
                              </option>
                            )}
                            {formData[index + 1]?.contact?.id &&
                              !showAllSite[index + 1] && (
                                <option value="callFunctionOption">
                                  Show All Website
                                </option>
                              )}
                            {formData[index + 1]?.contact?.id &&
                              !showAllSite[index + 1]
                              ? refUrl
                                  .filter(
                                    (item) =>
                                      item?.contact ===
                                      formData[index + 1]?.contact?.id
                                  )
                                  ?.map((filteredItem, index) => (
                                    <option
                                      key={index}
                                      value={JSON.stringify(filteredItem)}
                                      data-id={filteredItem?.id}
                                    >
                                      {filteredItem?.name}
                                    </option>
                                  ))
                              : refUrl?.map((item, index) => (
                                  <option
                                    key={index}
                                    value={JSON.stringify(item)}
                                    data-id={item?.id}
                                  >
                                    {item?.name}
                                  </option>
                                ))}
                          </Form.Select>
                        </Form.Group>
                      </div>

                      <div className="col-12 col-lg-6">
                        <Form.Group
                          className="form-group"
                          style={{ marginTop: "0px", marginBottom: "5px" }}
                        >
                          <Form.Label className="model-form-label">
                            {" "}
                            Desired Anchor Text
                          </Form.Label>
                          <Form.Control
                            // required
                            type="text"
                            name="anchor_text"
                            value={formData[index + 1]?.anchor_text}
                            onChange={(event) =>
                              handleInputChange(index + 1, event)
                            }
                            placeholder="Enter anchor text"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {/* for the contact  */}
                    <div className="row">
                      <Form.Group
                        className="form-group"
                        style={{
                          marginTop: "0px",
                          marginBottom: "5px",
                          height: "88px",
                        }}
                      >
                        <Form.Label className="model-form-label">
                          Contact
                        </Form.Label>
                        {useExistingContect &&
                          useExistingContect[index + 1] &&
                          showArray &&
                          showArray[index + 1] &&
                          showArray[index + 1][0] === true ? (
                          <Alert
                            show={
                              showArray &&
                              showArray[index + 1][index + 1] === true
                            }
                          >
                            <div className="arlt-main-box">
                              <span className="alrt-inf0-icon">
                                <FontAwesomeIcon icon={faInfo} />
                              </span>
                              {showArray &&
                                showArray[index + 1][1] === false &&
                                useExistingContect &&
                                useExistingContect[index + 1] ? (
                                <span className="alrt-inf0-text">
                                  Do you want to change the contact for this
                                  site to{" "}
                                  {`${formData[index + 1]?.contact?.first_name
                                    } ${formData[index + 1]?.contact?.last_name
                                    }`}{" "}
                                  ?
                                </span>
                              ) : showArray &&
                                showArray[index + 1][1] === true ? (
                                <span className="alrt-inf0-text">
                                  Are you sure to clear contact ?
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <Button
                                className="alrt-btn"
                                variant="outline-danger"
                                onClick={() => {
                                  updateShowArray(index + 1, false, true);
                                  updateNewContact(index + 1, true);
                                  updateExContact(index + 1, false);
                                }}
                                style={{ marginRight: "20px" }}
                              >
                                Yes
                              </Button>
                              <Button
                                className="alrt-btn"
                                variant="outline-danger"
                                onClick={() => {
                                  updateShowArray(index + 1, false, true);
                                  updateNewContact(index + 1, false);
                                  updateExContact(index + 1, true);
                                }}
                              >
                                No
                              </Button>
                            </div>
                          </Alert>
                        ) : (
                          ""
                        )}

                        {formData[index + 1]?.contact?.first_name ===
                          "Select a contact" &&
                          showArray &&
                          showArray[index + 1][0] === true && (
                            <Alert show={true}>
                              <div className="arlt-main-box">
                                <span className="alrt-inf0-icon">
                                  <FontAwesomeIcon icon={faInfo} />
                                </span>
                                <span className="alrt-inf0-text">
                                  Are you sure to clear contact ?
                                </span>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                <Button
                                  className="alrt-btn"
                                  variant="outline-danger"
                                  onClick={() => {
                                    updateShowArray(index + 1, false, true);
                                    updateNewContact(index + 1, true);
                                    updateExContact(index + 1, false);
                                    setFormData((prevFormData) => {
                                      const updatedFormData = [...prevFormData];
                                      updatedFormData[index + 1] = {
                                        ...updatedFormData[index + 1],
                                        exContact: null,
                                      };
                                      return updatedFormData;
                                    });
                                  }}
                                  style={{ marginRight: "20px" }}
                                >
                                  Yes
                                </Button>
                                <Button
                                  className="alrt-btn"
                                  variant="outline-danger"
                                  onClick={() => {
                                    updateShowArray(index + 1, false, true);
                                    updateNewContact(index + 1, false);
                                    updateExContact(index + 1, true);
                                  }}
                                >
                                  No
                                </Button>
                              </div>
                            </Alert>
                          )}
                        <div className="model-drop-top">
                          <div style={{ width: "100%" }}>
                            <Form.Control
                              className="dropdown-addref-sec2"
                              value={
                                formData[index + 1]?.contact &&
                                  !useExistingContect[index + 1]
                                  ? `${formData[index + 1]?.contact?.first_name
                                  } ${formData[index + 1]?.contact?.last_name
                                  }`
                                  : useExistingContect[index + 1] &&
                                    formData[index + 1]?.exContact
                                    ? `${formData[index + 1]?.exContact?.first_name
                                    } ${formData[index + 1]?.exContact?.last_name
                                    }`
                                    : formData[index + 1]?.exContact
                                      ? `${formData[index + 1]?.exContact?.first_name
                                      } ${formData[index + 1]?.exContact?.last_name
                                      }`
                                      : ""
                              }
                              placeholder="Select a contact"
                            />
                            <Form.Select
                              name="contact"
                              className="dropdown-addref-sec3"
                              onChange={(event) => {
                                handleInputChange(index + 1, event);
                                const selectedContact = JSON.parse(
                                  event.target.value
                                );
                                updateBooleanArray(index + 1, selectedContact);
                                handleShowAllSite(index + 1);
                              }}
                            >
                              <option
                              disabled
                                value={JSON.stringify({
                                  id: null,
                                  first_name: "Select a contact",
                                  last_name: "",
                                })}
                              >
                                Select Contact
                              </option>
                              <option
                                value={JSON.stringify({
                                  id: formData[0]?.exContact?.id,
                                  first_name: "Select a contact",
                                  last_name: "",
                                })}
                                style={{ fontWeight: "bold" }}
                              >
                                Clear Contact
                              </option>
                              {contactData &&
                                contactData?.results?.map((contact) => (
                                  <option
                                    key={contact?.id}
                                    value={JSON.stringify(contact)}
                                  >
                                    {`${contact?.first_name} ${contact?.last_name}`}
                                  </option>
                                ))}
                            </Form.Select>
                          </div>
                          <Button
                            className="backlink-button4"
                            variant="primary"
                            style={{ marginLeft: "15px" }}
                            onClick={() =>
                              handleEdit(index + 1, formData[index + 1])
                            }
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                          <Button
                            className="backlink-button6"
                            variant="primary"
                            onClick={() => {
                              handleAddNewContact();
                              setIndex(index + 1);
                            }}
                          >
                            <FontAwesomeIcon icon={faUserPlus} />
                          </Button>
                        </div>
                      </Form.Group>
                    </div>

                    {/* <div className="row">
                      <Form.Group
                        className="form-group"
                        style={{ marginTop: "0px", marginBottom: "5px" }}
                      >
                        <Form.Label className="model-form-lable">
                          Contact{" "}
                        </Form.Label>
                        <div className="model-drop-top">
                          <Form.Select
                            name="contact"
                            // value={contact[index + 1]?.contact}
                            onChange={(event) =>
                              handleInputChange(index + 1, event)
                            }
                          >
                            {contactData &&
                              contactData?.results?.map((contact) => (
                                <option
                                  key={contact.id}
                                  value={JSON.stringify(contact)}
                                >
                                  {`${contact?.first_name} ${contact?.last_name}`}
                                </option>
                              ))}
                          </Form.Select>

                          <Button
                            className="backlink-button4"
                            variant="primary"
                            style={{ marginLeft: "15px" }}
                            onClick={() => handleEdit(formData[index + 1])}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                        </div>
                      </Form.Group>
                    </div> */}

                    {/* <div className="row">
                      <div className="col-12 col-lg-6">
                        <Form.Group
                          className="form-group"
                          style={{ marginTop: "0px", marginBottom: "5px", height: "88px" }}
                        >
                          <Form.Label className="model-form-label">
                            Website link <span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <Form.Control
                            required
                            type="text"
                            name="name"
                            className="dropdown-addref-sec1"
                            value={formData[0].referenced_url}
                            onChange={(event) => handleInputChange(0, event)}
                            placeholder="Write Ref URL or Select"
                          />

                          <Form.Select
                            required
                            name="name"
                            value={formData[0].referenced_url}
                            className="dropdown-addref-sec"
                            onChange={(event) => handleInputChange(0, event)}
                          >
                            <option value="" disabled selected>
                              Select an option
                            </option>
                            {refUrl &&
                              refUrl.map((item, index) => (
                                <option key={index} value={item.name} data-id={item.id}>
                                  {item?.name}
                                </option>
                              ))}
                          </Form.Select>

                        </Form.Group>
                      </div>

                      <div className="col-12 col-lg-6">
                        <Form.Group
                          className="form-group"
                          style={{ marginTop: "0px", marginBottom: "5px" }}
                        >
                          <Form.Label className="model-form-lable">
                            {" "}
                            Anchor Text
                          </Form.Label>
                          <Form.Control
                            // required
                            type="text"
                            name="anchor_text"
                            value={contact[index + 1]?.anchor_text}
                            onChange={(event) =>
                              handleInputChange(index + 1, event)
                            }
                            placeholder="Painting Service"
                          />
                        </Form.Group>
                      </div>
                    </div> */}

                    <Form.Group
                      className="form-group"
                      style={{ marginTop: "0px", marginBottom: "5px" }}
                    >
                      <Form.Label className="model-form-label">
                        Additional Details
                      </Form.Label>
                      <Form.Control
                        // required
                        as="textarea"
                        rows="2"
                        name="notes"
                        value={contact[index + 1]?.notes}
                        onChange={(event) =>
                          handleInputChange(index + 1, event)
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
              </>
            ))}
            <div className="mt-3 d-flex model-btn-flex">
              <Button
                className="backlink-button1"
                variant="primary"
                type="submit"
              >
                {loader ? "Saving... " : "Save"}
              </Button>
              <Button
                className="backlink-button2 d-flex ms-auto align-items-center"
                type="button"
                onClick={handleAddMore}
              >
                <FontAwesomeIcon className="btn-icon" icon={faPlusCircle} /> Add
                more
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* add new contact modal  */}
      {showNewContactModal && (
        <Modal
          backdrop="static"
          show={showNewContactModal}
          onHide={handleCloseNewContactModal}
        >
          <Modal.Header closeButton>
            <div className="model-tittle-1">Add Contact</div>
          </Modal.Header>

          <Modal.Body>
            <Form method="POST" onSubmit={postContact}>
              <div className="add-from-link">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <Form.Group
                      className="form-group"
                      style={{ marginTop: "0px", marginBottom: "5px" }}
                    >
                      <Form.Label className="model-form-lable">
                        {" "}
                        First Name
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="first_name"
                        value={contactForm[0]?.first_name}
                        onChange={(event) => handleInputChangeContact(0, event)}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-12 col-lg-6">
                    <Form.Group
                      className="form-group"
                      style={{ marginTop: "0px", marginBottom: "5px" }}
                    >
                      <Form.Label className="model-form-lable">
                        {" "}
                        Last Name
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="last_name"
                        value={contactForm[0]?.last_name}
                        onChange={(event) => handleInputChangeContact(0, event)}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-lg-12">
                    <Form.Group
                      className="form-group"
                      style={{ marginTop: "0px", marginBottom: "5px" }}
                    >
                      <Form.Label className="model-form-lable">
                        {" "}
                        Email
                      </Form.Label>
                      <Form.Control
                        // required
                        type="email"
                        name="email"
                        value={contactForm[0]?.email}
                        onChange={(event) => handleInputChangeContact(0, event)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>

              {contactForm?.slice(1)?.map((contact, index) => (
                <div
                  style={{ marginTop: "10px" }}
                  key={index}
                  className="add-from-link"
                >
                  <div className="refrence-model-main">
                    <Button
                      className="close-btn ref-btn-model"
                      type="button"
                      onClick={() => handleRemoveContact(index + 1)}
                    >
                      <img src={close} alt="Your Image" />
                    </Button>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <Form.Group
                          className="form-group"
                          style={{ marginTop: "0px", marginBottom: "5px" }}
                        >
                          <Form.Label className="model-form-lable">
                            {" "}
                            First Name
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="first_name"
                            value={contact[index + 1]?.first_name}
                            onChange={(event) =>
                              handleInputChangeContact(index + 1, event)
                            }
                          />
                        </Form.Group>
                      </div>

                      <div className="col-12 col-lg-6">
                        <Form.Group
                          className="form-group"
                          style={{ marginTop: "0px", marginBottom: "5px" }}
                        >
                          <Form.Label className="model-form-lable">
                            {" "}
                            Last Name
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="last_name"
                            value={contact[index + 1]?.last_name}
                            onChange={(event) =>
                              handleInputChangeContact(index + 1, event)
                            }
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-lg-12">
                        <Form.Group
                          className="form-group"
                          style={{ marginTop: "0px", marginBottom: "5px" }}
                        >
                          <Form.Label className="model-form-lable">
                            {" "}
                            Email
                          </Form.Label>
                          <Form.Control
                            // required
                            type="email"
                            name="email"
                            value={contact[index + 1]?.email}
                            onChange={(event) =>
                              handleInputChangeContact(index + 1, event)
                            }
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="mt-3 d-flex model-btn-flex">
                <Button
                  className="backlink-button1"
                  variant="primary"
                  type="submit"
                >
                  {contactLoader ? "Saving.. " : "Save"}
                </Button>

                {/* <Button
                  className="backlink-button"
                  variant="primary"
                  onClick={handleCloseNewContactModal}
                >
                  Cancel
                </Button> */}

                <Button
                  className="backlink-button2 model-add-btn d-flex ms-auto align-items-center justify-content-center"
                  type="button"
                  onClick={handleAddMoreContacts}
                >
                  <FontAwesomeIcon className="btn-icon" icon={faPlusCircle} />{" "}
                  Add More
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
      {/* edit contact modal  */}
      {showEditModal && (
        <EditContact
          showEditModal={showEditModal}
          handleCloseEditModal={handleCloseEditModal}
          userForEdit={editUserData ? editUserData : contactData?.results[0]}
          fetchContact={fetchContact}
        />
      )}
    </>
  );
};

export default AddRefrenceModel;