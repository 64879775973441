import React, { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUnlockAlt,
  faAddressCard,
  faSignOutAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { sidebarData } from "./sidebar/index";
import { Routess } from "../routes";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import Logoimg from "../assets/img/logo tracklink 1.png";
import Down from "../assets/img/chevron-down.svg";
import "../../src/style.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import apiService from "../apiServices/apiService";
// import Avtar from "../assets/img/avatar.svg";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props = {}) => {
  const location = useLocation();
  const [userData, setUserData] = useState([]);
  // console.log("userData+++++++++++", userData);
  let token = localStorage.getItem("trp_token");
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await apiService.fetchUserData();
        setUserData(data);
      } catch (error) {
        console.log("error during calling data", error);
      }
    };
    getData();
  }, [token]);
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const history = useNavigate();
  function logoutU(event, history) {
    event.preventDefault();
    localStorage?.clear();
    toast.error("Logout Successfully");
    window.location.reload();
  }
  const logoutUser = async () => {
    try {
      await apiService.logout();
      // toast.error("Logout Successfully");
      await localStorage.removeItem("trp_token");
      setTimeout(() => {
        history("/");
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error occurred during logout", error);
    }
  };

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <div className="mobile-nav-div">
          <Navbar.Toggle
            as={Button}
            aria-controls="main-navbar"
            onClick={onCollapse}
          >
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>

          <Navbar.Brand
            className="me-lg-5"
            as={Link}
            to={Routess.DashboardOverview.path}
          >
            <Image src={Logoimg} />
          </Navbar.Brand>

          <div>
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  {/* <Image
                    style={{ marginRight: "15px" }}
                    src={Avtar}
                    className="user-avatar md-avatar rounded-circle"
                  /> */}

                  <Image
                    style={{
                      marginRight: "15px",
                    }}
                    className="d-lg-block dropdown_sidebar_icon"
                    src={Down}
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right">
                <Dropdown.Item className="fw-bold">
                  <Link to="/change-password">
                    <button
                      // onClick={}
                      class="top-drop-menu-btn"
                      type="submit"
                    >
                      <FontAwesomeIcon icon={faUnlockAlt} className="me-2" />{" "}
                      Change Password
                    </button>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="fw-bold">
                  <button
                    onClick={logoutUser}
                    className="top-drop-menu-btn"
                    type="submit"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Navbar>
      {/* bbbbjb */}
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center ">
              <div className="d-flex align-items-center">
                <div className="d-block">
                  <Nav className="align-items-center">
                    <Dropdown as={Nav.Item}>
                      <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                        <div className="media d-flex align-items-center">
                          <div className="media-body ms-2 align-items-center d-lg-block">
                            <span className="users-name">Welcome, </span>
                            <span className="usera-name-bold">
                              {" "}
                              {userData?.first_name}
                            </span>
                          </div>
                          {/* <Image
                            style={{
                              marginRight: "15px",
                              zIndex: "11111",
                              paddingRight: "8px",
                            }}
                            className="d-lg-block"
                            src={Down}
                          /> */}
                        </div>
                      </Dropdown.Toggle>
                      {/* <Dropdown.Menu className="user-dropdown dropdown-menu-right"> */}
                        {/* <Dropdown.Item className="fw-bold"> */}
                          {/* <button
                            onClick={}
                            class="top-drop-menu-btn"
                            type="submit"
                          >
                            <FontAwesomeIcon icon={faAddressCard} className="me-2" />{" "}
                            Profile
                          </button> */}
                        {/* </Dropdown.Item> */}
                        <Dropdown.Divider />
                        {/* <Dropdown.Item className="fw-bold">
                          <Link to="/change-password">
                            <button
                              // onClick={}
                              class="top-drop-menu-btn"
                              type="submit"
                            >
                              <FontAwesomeIcon
                                icon={faUnlockAlt}
                                className="me-2"
                              />{" "}
                              Change Password
                            </button>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item className="fw-bold">
                          <button
                            onClick={(e) => logoutU(e, history)}
                            class="top-drop-menu-btn"
                            type="submit"
                          >
                            <FontAwesomeIcon
                              icon={faSignOutAlt}
                              className="me-2"
                            />{" "}
                            Sign Out
                          </button>
                        </Dropdown.Item> */}
                      {/* </Dropdown.Menu> */}
                    </Dropdown>
                  </Nav>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
                style={{ fontSize: "25px", color: "black" }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              {sidebarData?.map((nav, index) => {
                //  console.log(userData, nav.showTo, "navv tes====>t");

                // if(userData && JSON?.parse(userData)?.work===nav?.showTo ){

                return (
                  <NavItem
                    key={index}
                    title={nav.title}
                    link={nav.link}
                    icon={nav.icon}
                  />
                );
                // }
                // else return null
              })}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
