import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "./singin-up.css";
import apiService from "../../apiServices/apiService";

export default () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const newPassword = async (e) => {
    e.preventDefault();
    setLoader(true);

    if (
      oldPassword.trim() === "" ||
      password.trim() === "" ||
      confirmPassword.trim() === ""
    ) {
      setLoader(false);
      toast.error("Please fill in all fields");
      return;
    }
    const data = {
      old_password: oldPassword,
      password: password,
      password_confirm: confirmPassword,
    };
    try {
      const res = await apiService.changePassword(data);
      setLoader(false);
      if (res?.status === 200) {
        history("/");
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <div className="chnage-pw">
      <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-4">
        <div className="login-text1">
          <p>Change your Password</p>
          {/* <h2>Track Link Pro Account</h2> */}
        </div>
        <Form className="mt-4" method="POST">
          <Form.Group id="oldPassword" className="mb-3">
            <InputGroup>
              <InputGroup.Text className="input-grp1">
                <FontAwesomeIcon style={{ color: "black" }} icon={faKey} />
              </InputGroup.Text>
              <Form.Control
                className="input-grp"
                type="password"
                name="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                placeholder="Old Password"
                required
              />
            </InputGroup>
          </Form.Group>

          <Form.Group>
            <Form.Group id="password" className="mb-3">
              <InputGroup>
                <InputGroup.Text className="input-grp1">
                  <FontAwesomeIcon style={{ color: "black" }} icon={faKey} />
                </InputGroup.Text>
                <Form.Control
                  className="input-grp"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
              </InputGroup>
            </Form.Group>
          </Form.Group>

          <Form.Group>
            <Form.Group id="confirmPassword" className="mb-3">
              <InputGroup>
                <InputGroup.Text className="input-grp1">
                  <FontAwesomeIcon style={{ color: "black" }} icon={faKey} />
                </InputGroup.Text>
                <Form.Control
                  className="input-grp"
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  required
                />
              </InputGroup>
            </Form.Group>
          </Form.Group>

          <Button
            type="submit"
            onClick={(e) => {
              newPassword(e);
            }}
            className="login-btn"
          >
            {!loader ? "Change Password" : "Changing.."}
          </Button>
        </Form>
      </div>
    </div>
  );
};
