import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Modal, Image } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import close from "../../assets/img/Vector.svg";
import apiService from "../../apiServices/apiService";
import { toast } from "react-toastify";
import {
  faSyncAlt,
  faPlusCircle,
  faEllipsisVertical,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import upload from "../../assets/img/shape.svg";
import download from "../../assets/img/shape 1.svg";

const WebsiteAddMob = ({ show, handleClose, getData }) => {
  const [formData, setFormData] = useState([{ name: "", niche: "" }]);
  const [loader, setLoader] = useState(false);
  const [niche, setNiche] = useState([]);
  const [errorMsg, setErrorMag] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getData();
        if (response) {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    const dataTimeout = setTimeout(fetchData, 1000);
    return () => {
      clearTimeout(dataTimeout);
    };
  }, []);

  const downloadLink = async () => {
    try {
      const urlForDownload = await apiService.downloadSample();
      window.open(urlForDownload);
    } catch (error) {
      console.error("Error fetching download URL:", error);
    }
  };

  const refreshData = async () => {
    setIsLoading(true);
    try {
      const data = await getData();
    } catch (error) {
      console.log("error during refresh data", error);
    }
    setIsLoading(false);
  };
  //////////import CSV/////////
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    try {
      const response = await apiService.uploadFile(selectedFile);
      refreshData();
    } catch (error) {
      // Handle the error
      console.error("An error occurred:", error.message);
    }
  };
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFormData = [...formData];
    updatedFormData[index][name] = value;
    setFormData(updatedFormData);
  };

  const handleAddMore = () => {
    setFormData([...formData, { name: "", niche: "" }]);
  };

  const fetchNiche = async () => {
    const data = await apiService.fetchNicheName();
    setNiche(data);
  };

  useEffect(() => {
    fetchNiche();
  }, []);

  const handleRemove = (index) => {
    const links = [...formData];
    links.splice(index, 1);
    setFormData(links);
  };

  const postOwnedLink = async (e) => {
    e.preventDefault();
    setLoader(true);
    const data = await apiService?.postTrackLink(formData);
    setErrorMag(data);
    if (data?.data?.errors?.length === 0) {
      setTimeout(() => {
        setFormData([{ name: "", niche: "" }]);

        // fetchNiche();
      }, 3000);
    } else {
      // this is the logic for remove added data form tha form aaray
      const indices = data?.response?.data?.errors?.map((error) => error.index);
      const indicesToKeep = indices || [];
      indicesToKeep?.sort((a, b) => b - a);
      const filteredFormData = formData.filter((item, index) =>
        indicesToKeep.includes(index)
      );
      setTimeout(() => {
        setFormData(filteredFormData);
      }, 1000);
    }
    setLoader(false);
  };
  const goToAddWebsitePage = () => {
    navigate("/add-website");
  };
  const goToImportCsvPage = () => {
    navigate("/import-csv");
  };

  // console.log("data", errorMsg);
  return (
    <>
      {/* Mobile-View */}

      <div
        style={{
          margin: "19px 10px",
        }}
      >
        <div className="top-button-mobile" style={{ marginBottom: "30px" }}>
          <div className="top-btn-div">
            <Button
              type="submit"
              onClick={(e) => {
                refreshData();
                toast.success("Data Refresh Success!");
              }}
              className="mbil-btn1"
              style={{ background: "#ff9219" }}
            >
              <FontAwesomeIcon style={{ fontSize: "35px" }} icon={faSyncAlt} />
            </Button>
            <span className="top-btn-text">Refresh </span>
          </div>

          <div className="top-btn-div">
            <Button
              className="mbil-btn1"
              style={{ background: "#2bb269" }}
              onClick={goToAddWebsitePage}
            >
              <FontAwesomeIcon
                style={{ fontSize: "35px" }}
                icon={faPlusCircle}
              />
            </Button>
            <sapn className="top-btn-text">Add Your Website </sapn>
          </div>

          <input
            type="file"
            className="custom-file-input"
            id="customFile"
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <div className="top-btn-div">
            <Button
              type="button"
              className="mbil-btn1"
              style={{ background: "#1118b9" }}
              onClick={goToImportCsvPage}
            >
              <Image
                style={{ marginBottom: "3px", width: "35px" }}
                src={upload}
                alt="Your Image"
              />{" "}
            </Button>
            <span className="top-btn-text">Import your websites</span>
          </div>

          <div className="top-btn-div">
            <Button
              type="submit"
              onClick={downloadLink}
              style={{ background: "#ff9219" }}
              className="mbil-btn1"
            >
              <Image
                style={{ marginBottom: "3px", width: "35px" }}
                src={download}
                alt="Your Image"
              />{" "}
            </Button>
            <span className="top-btn-text">Download template</span>
          </div>
        </div>
        <Form method="POST" onSubmit={postOwnedLink}>
          <div className="add-from-link-mobile-main">
            <div className="add-from-link-mobile">
              <h3 style={{ fontWeight: "700" }}>Add Link</h3>
              <Form.Group className="form-group">
                <Form.Label style={{ fontWeight: "800" }}>
                  {" "}
                  Link URL{" "}
                  {errorMsg?.response?.status === 400 &&
                    errorMsg?.response?.data?.errors &&
                    errorMsg?.response?.data?.errors[0]?.errors && (
                      <span className="text-danger">
                        {errorMsg?.response?.data?.errors[0]?.errors?.name
                          ? errorMsg?.response?.data?.errors[0]?.errors?.name[0]
                          : errorMsg?.response?.data?.errors[0]?.errors}
                      </span>
                    )}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="name"
                  value={formData[0].name}
                  onChange={(event) => handleInputChange(0, event)}
                  placeholder="Enter website URL"
                />
              </Form.Group>

              {/* <Form.Group className="form-group">
                    <Form.Label> Niche</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name="niche"
                        value={formData[0].niche}
                        onChange={(event) => handleInputChange(0, event)}
                        placeholder="Paintings"
                    />
                </Form.Group> */}

              <Form.Group className="form-group" id={`niche-${0}`}>
                <Form.Label style={{ fontWeight: "800", marginTop: "8px" }}>
                  Niche
                </Form.Label>
                <Form.Select
                  required
                  name="niche"
                  value={formData[0].niche}
                  onChange={(event) => handleInputChange(0, event)}
                >
                  <option value="" disabled>
                    Select Niche
                  </option>
                  {niche?.map((item, ind) => (
                    <option key={item.id} value={item.id}>
                      {item?.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            {formData.slice(1).map((link, index) => (
              <div className="add-from-link" key={index}>
                <div>
                  <Form.Group className="form-group">
                    <div className="close-set">
                      <Form.Label style={{ fontWeight: "800" }}>
                        Link URL
                        {errorMsg?.response?.status === 400 &&
                          errorMsg?.response?.data?.errors &&
                          errorMsg?.response?.data?.errors[index + 1]
                            ?.errors && (
                            <span className="text-danger">
                              {errorMsg?.response?.data?.errors[index + 1]
                                ?.errors?.name
                                ? errorMsg?.response?.data?.errors[index + 1]
                                    ?.errors?.name[0]
                                : errorMsg?.response?.data?.errors[index + 1]
                                    ?.errors}
                            </span>
                          )}
                      </Form.Label>
                      <Button
                        className="close-btn"
                        type="button"
                        onClick={() => handleRemove(index + 1)}
                      >
                        <img src={close} alt="Your Image" />
                      </Button>
                    </div>

                    <Form.Control
                      required
                      type="text"
                      name="name"
                      value={link.name}
                      onChange={(event) => handleInputChange(index + 1, event)}
                      placeholder="Enter website URL"
                    />
                  </Form.Group>
                  <Form.Group className="form-group" id={`niche-${index + 1}`}>
                    <Form.Label style={{ fontWeight: "800", marginTop: "8px" }}>
                      Niche
                    </Form.Label>
                    <Form.Select
                      required
                      name="niche"
                      value={link.niche}
                      onChange={(event) => handleInputChange(index + 1, event)}
                    >
                      <option value="" disabled>
                        Select Niche
                      </option>
                      {niche?.map((item, ind) => (
                        <option key={ind} value={item.id}>
                          {item?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
            ))}
            <Button
              className="backlink-button2-mob d-flex ms-auto align-items-center"
              type="button"
              onClick={handleAddMore}
            >
              <FontAwesomeIcon className="btn-icon" icon={faPlusCircle} /> Add
              More
            </Button>
          </div>

          <div className="mt-3 mt-3 d-flex justify-content-center w-100 mb-3">
            <Button
              className="backlink-button1-mob"
              variant="primary"
              type="submit"
            >
              {loader ? "Submitting " : "Submit Link"}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
export default WebsiteAddMob;
