import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "@themesberg/react-bootstrap";
import apiService from "../../apiServices/apiService";
export const EditContact = ({
  showEditModal,
  handleCloseEditModal,
  userForEdit,
  fetchContact,
  backlink,
  fetchBacklinks,
}) => {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (backlink) {
      fetchContactById();
    } else {
      setFormData(userForEdit);
    }
  }, [userForEdit, backlink]);

  const fetchContactById = async () => {
    const response = await apiService.getContactById(backlink.contact);
    if (response) {
      setFormData(response);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    const data = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
    };
    try {
      const response = await apiService.editContact(formData.id, data);
      if (backlink) {
        fetchBacklinks();
      } else {
        fetchContact();
      }
      setTimeout(() => {
        handleCloseEditModal();
      }, 2000);
      setLoader(false);
    } catch (error) {
      console.error("Error editing contact:", error);
    }
  };

  return (
    <div>
      <Modal backdrop="static" show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <div className="model-tittle-1">Edit Contact</div>
        </Modal.Header>

        <Modal.Body>
          <Form method="POST" onSubmit={handleSubmit}>
            <div className="add-from-link">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Form.Group
                    className="form-group"
                    style={{ marginTop: "0px", marginBottom: "5px" }}
                  >
                    <Form.Label className="model-form-lable">
                      First Name
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="first_name"
                      value={formData?.first_name || ""}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </div>

                <div className="col-12 col-lg-6">
                  <Form.Group
                    className="form-group"
                    style={{ marginTop: "0px", marginBottom: "5px" }}
                  >
                    <Form.Label className="model-form-lable">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="last_name"
                      value={formData?.last_name || ""}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-12">
                  <Form.Group
                    className="form-group"
                    style={{ marginTop: "0px", marginBottom: "5px" }}
                  >
                    <Form.Label className="model-form-lable">Email</Form.Label>
                    <Form.Control
                      // required
                      type="email"
                      name="email"
                      value={formData?.email || ""}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="mt-3 edit-model-btn d-flex">
                <Button
                  className="backlink-button1"
                  variant="primary"
                  type="submit"
                  style={{textAlign:'center'}}
                >
                  {loader ? 'Saving...' : 'Save'}
                </Button>
                {/* <Button
                  className="backlink-button"
                  variant="primary"
                  onClick={handleCloseEditModal}
                >
                  Cancel
                </Button> */}
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
