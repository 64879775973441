import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  ListGroup,
  Tooltip,
  OverlayTrigger,
  Form,
  Navbar,
  Nav,
  Badge,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Signin from "./examples/Signin";
import BacklinkTable from "./components/BacklinkTable";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
export default () => {
  const history = useNavigate();
  const [token, setToken] = useState("")
  useEffect(() => {
    const token = localStorage.getItem("trp_token")
    setToken(token)
    if (!token) {
      history("/examples/sign-in")
    }
  }, [history]);

  return (
    <>
      <section className="main-dashboard">
        <SkeletonTheme baseColor="#202020" highlightColor="red">
          <BacklinkTable />
        </SkeletonTheme>

      </section>
      <footer></footer>
    </>
  );
};
