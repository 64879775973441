// apiService.js
// remove token to trp_token
import axios from "axios";
import { baseUrl } from "../config/config";
import { toast } from "react-toastify";

let token = localStorage.getItem("trp_token");

const apiService = {
  fetchUserData: async () => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.get(`${baseUrl}/api/accounts/profile/`, {
        headers: {
          accept: "application/json",
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("response data", response);
      return response.data;
    } catch (error) {
      toast.success("");
    }
  },
  fetchNicheName: async () => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.get(
        `${baseUrl}/api/v1/tracklinks/niche-urls/`
      );
      return response?.data;
    } catch (error) {
      toast.success("");
    }
  },
  fetchTackLink: async () => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.get(
        `${baseUrl}/api/v1/tracklinks/owned-urls/`,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      toast.success("");
    }
  },
  fetchLinkById: async (id) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.get(
        `${baseUrl}/api/v1/tracklinks/owned-urls/${id}/`,
        {
          headers: {
            Accept: "*/*",
            Authorization: `Token ${token}`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      toast.success("");
    }
  },
  postTrackLink: async (postData) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.post(
        `${baseUrl}/api/v1/tracklinks/owned-urls/`,
        postData, // Move postData outside the object
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response && response.data.successful_urls) {
        toast.success("Link added successfully!");
      }
      return response;
    } catch (error) {
      // if (
      //   error?.response &&
      //   error?.response?.data &&
      //   error?.response?.data?.errors
      // ) {
      //   error?.response?.data?.errors?.forEach((item, index) =>
      //     toast.error(item?.errors)
      //   );
      // }
      if (
        error?.response &&
        error?.response?.data &&
        error?.response?.data?.successful_urls &&
        error?.response?.data?.successful_urls.length > 0
      ) {
        toast.success(
          `${error?.response?.data?.successful_urls.length} Link added successfully!`
        );
      }
      // if (error?.response?.status === 400) {
      //   error?.response?.status === 400 &&
      //     error?.response?.data?.errors?.forEach((item, index) =>
      //       toast.error(item?.errors?.name && item?.errors?.name[0])
      //     );
      // }
      // toast.error("Please add proper Link");
      return error;
    }
  },
  updateTrackLink: async (id, postData) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.patch(
        `${baseUrl}/api/v1/tracklinks/owned-urls/${id}/`,
        postData, // Move postData outside the object
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      toast.success("Link upadted successfully!");
      return response;
    } catch (error) {
      // if (error.response && error.response.data && error.response.data.errors) {
      //   error.response.data.errors.forEach((item, index) => toast.error(item.errors));
      // }
      // if (error.response && error.response.data && error.response.data.successful_urls) {
      //   error.response.data.successful_urls.forEach((item, index) => toast.success("Link added successfully!"));
      // }
      // toast.error("Please add proper Link");
      console.error("Post Track Link Error", error);
    }
  },
  postTrackLinkRefrence: async (postData) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.post(
        `${baseUrl}/api/v1/tracklinks/requested-backlinks/create_requested_backlinks/`,
        postData,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response && response.data.successful_urls) {
        toast.success("Link added successfully!");
      }
      return response;
    } catch (error) {
      // if (error.response && error.response.data && error.response.data.errors) {
      //   error.response.data.errors &&
      //     error.response.data.errors.forEach((item, index) =>
      //       toast.error(item.errors?.non_field_errors && item.errors?.non_field_errors[0])
      //     );
      // }
      // if (error.response && error.response.data && error.response.data.errors) {
      //   error.response.data.errors &&
      //     error.response.data.errors.forEach((item, index) =>
      //       toast.error(item.errors?.name && item.errors?.name[0])
      //     );
      // }
      if (
        error.response &&
        error.response.data &&
        error.response.data.successful_urls &&
        error?.response?.data?.successful_urls.length > 0
      ) {
        toast.success(
          `${error?.response?.data?.successful_urls.length} Link added successfully!`
        );
      }
      return error;

      // let errorMessage = error ? error?.response?.data?.errors[0]?.errors?.non_field_errors[0] : "Error Adding link";
      // if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0 && error.response.data.errors[0].errors && error.response.data.errors[0].errors.non_field_errors && error.response.data.errors[0].errors.non_field_errors.length > 0) {
      //     errorMessage = error.response.data.errors;
      // }

      // if (error.response && error.response.data && error.response.data.successful_urls) {
      //   error?.response?.data?.successful_urls.forEach((item, index) => toast.success("Link added successfully!"));
      // }
      // toast.error(errorMessage)
      // toast.error(error.response.data.errors[0].errors.non_field_errors[0]);
      // console.log("Post Track Link Error", error?.response?.data?.errors[0]?.errors?.non_field_errors[0]);
    }
  },
  deleteTrackLink: async (id) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.delete(
        `${baseUrl}/api/v1/tracklinks/owned-urls/${id}/`,
        {
          headers: {
            // accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      // console.log("response data", response.data);
      return response.data;
    } catch (error) {
      console.error("Delete Track Link Error", error);
    }
  },
  downloadSample: async (id) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.get(
        `${baseUrl}/api/v1/tracklinks/download-xlsx/1`,
        {
          headers: {
            // accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      // console.log("response data", response.data.url);
      return response.data.url;
    } catch (error) {
      console.error("Download Track Link Error", error);
    }
  },
  changePassword: async (postData) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.post(
        `${baseUrl}/api/accounts/change-password/`,
        postData, // Move postData outside the object
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response?.data) {
        toast.success(response?.data?.detail);
      }
      return response;
    } catch (error) {
      if (error?.response && error?.response?.data?.old_password) {
        toast.error(error?.response?.data?.old_password[0]);
      }
      if (error?.response && error?.response?.data?.password) {
        toast.error(error?.response?.data?.password[0]);
      }
      if (error?.response && error?.response?.data?.password_confirm) {
        toast.error(error?.response?.data?.password_confirm[0]);
      }
    }
  },
  uploadFile: async (file) => {
    try {
      const token = localStorage.getItem("trp_token");
      const formData = new FormData();
      formData.append("SampleUrlUpload", file);

      const response = await axios.post(
        `${baseUrl}/api/v1/tracklinks/owned-urls/upload_urls/`,
        formData,
        {
          headers: {
            accept: "*/*",
            Authorization: `Token ${token}`,
            "Content-Disposition":
              'attachment; filename="SampleUrlUpload.xlsx"',
          },
        }
      );

      toast.success("File uploaded successfully");
      return response.data;
    } catch (error) {
      toast.error("Error uploading file");
      // console.log("Upload File Error:", error);
    }
  },
  postContactdata: async (postData) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.post(
        `${baseUrl}/api/v1/tracklinks/contacts/`,
        postData,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      toast.success("Contact added successfully!");
      return response;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        error?.response?.data?.errors
      ) {
        error?.response?.data?.errors?.forEach((item, index) =>
          toast.error(item?.errors)
        );
      }
      if (
        error?.response &&
        error?.response?.data &&
        error?.response?.data?.successful_contacts
      ) {
        error?.response?.data?.successful_contacts?.forEach((item, index) =>
          toast.success("Contact added successfully!")
        );
      }
      if (error?.response?.status === 400) {
        error?.response?.status === 400 &&
          error?.response?.data?.errors?.forEach((item, index) =>
            toast.error(item?.errors?.email && item?.errors?.email[0])
          );
      }
      // console.log("error+++", error);
      return error;
    }
  },
  // postContactdata: async (postData) => {
  //   try {
  //     const token = localStorage.getItem("trp_token");
  //     const response = await axios.post(
  //       `${baseUrl}/api/v1/tracklinks/contacts/`,
  //       postData,
  //       {
  //         headers: {
  //           accept: "application/json",
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${token}`,
  //         },
  //       }
  //     );
  //     toast.success("Contact added successfully");
  //     return response;
  //   } catch (error) {
  //     toast.error("Please add proper contact");
  //     // console.log("Post contact Error", error);
  //     return error;
  //   }
  // },
  getContact: async () => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.get(
        `${baseUrl}/api/v1/tracklinks/contacts/`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Contact data", response);
      return response.data;
    } catch (error) {
      console.log("Fetch Contact Data Error", error);
    }
  },
  getContactById: async (id) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.get(
        `${baseUrl}/api/v1/tracklinks/contacts/${id}/`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Contact data", response);
      return response.data;
    } catch (error) {
      console.log("Fetch Contact Data Error", error);
    }
  },
  editContact: async (id, editPayload) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.patch(
        `${baseUrl}/api/v1/tracklinks/contacts/${id}/`,
        editPayload,
        {
          headers: {
            accept: "application/json",
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Edit Contact successfully");
      return response.data;
    } catch (error) {
      console.log("Edit Contact Error", error);
    }
  },
  getRefUrl: async (page) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.get(
        `${baseUrl}/api/v1/tracklinks/ref-urls/`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data.results;
    } catch (error) {
      console.log("error geting reflink", error);
    }
  },
  updateRequestedBacklinks: async (id, updatedData) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.patch(
        `${baseUrl}/api/v1/tracklinks/requested-backlinks/${id}/update_requested_backlinks`,
        updatedData,
        // `${baseUrl}/api/v1/tracklinks/requested-backlinks/${id}/`
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      toast.success("Reference URL updated successfully");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMsg = error.response.data.errors[0].errors;
        toast.error(`Error updating reference URL: ${errorMsg}`);
      } else {
        toast.error("Error updating reference URL");
      }
      console.error("Update Reference URL Error:", error);
      throw error;
    }
  },
  getRequestedBacklinckById: async (id) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.get(
        `${baseUrl}/api/v1/tracklinks/requested-backlinks/${id}/`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error geting reflink", error);
    }
  },
  sendEmail: async (id) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.post(
        `${baseUrl}/api/v1/tracklinks/requested-backlinks/${id}/send_email/`,
        null,
        {
          headers: {
            Accept: "application/json", // Example value, replace with the correct one
            Authorization: `Token ${token}`,
          },
        }
      );
      toast.success(response.data.success);
      return response;
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to send email");
      // throw error; // Re-throw the error to handle it outside if necessary
    }
  },
  deleteRequstedBacklinks: async (id) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.delete(
        `${baseUrl}/api/v1/tracklinks/requested-backlinks/${id}/`,
        {
          headers: {
            // accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      // console.log("response data", response.data);
      toast.success("Link Deleted successfully");
      return response.data;
    } catch (error) {
      console.log("Delete Track Link Error", error);
    }
  },
  postFeedback: async (feedbackData) => {
    try {
      const token = localStorage.getItem("trp_token");
      const response = await axios.post(
        `${baseUrl}/api/v1/tracklinks/feedback/`,
        feedbackData,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      toast.success("Feedback submitted successfully!");
      return response;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors;
        if (Array.isArray(errors)) {
          errors.forEach((item) => toast.error(item.errors));
        } else {
          toast.error("An error occurred while submitting feedback.");
        }
      } else {
        // Handle other specific error cases if needed
        // console.log("Post Feedback Error", error);
        toast.error("An error occurred while submitting feedback.");
      }
      // console.log("erroe", error);
    }
  },
  logout: async () => {
    try {
      const token = localStorage.getItem("trp_token");
      const data = {
        revoke_token: true,
      };
      // console.log(data.revoke_token);
      const response = await axios.post(
        `${baseUrl}/api/accounts/logout/`,
        data,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      toast.success("Logout successfully!");
      return response;
    } catch (error) {}
  },
};

export default apiService;
