import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import {
  faSyncAlt,
  faPlusCircle,
  faEnvelope,
  faUnlockAlt,
  faKey,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Table,
  Image,
  Container,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import download from "../../assets/img/shape 1.svg";
import close from "../../assets/img/Vector.svg";
import uploadicon from "../../assets/img/uploadicon.svg";
import check from "../../assets/img/Check_fill.png";
import dell from "../../assets/img/Dell_fill.png";
import apiService from "../../apiServices/apiService";
import { toast } from "react-toastify";
import AddWoned from "./AddWoned";

import AddRefrence from "./AddRefrence";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadError, setUploadError] = useState(true);
  const [selectedTab, setSelectedTab] = useState("owned");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    handleUpload();
  };
  const handleUpload = () => {
    // setUploadError(true);
    apiService.uploadFile(selectedFile);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const downloadLink = async () => {
    try {
      const urlForDownload = await apiService.downloadSample();
      window.open(urlForDownload);
    } catch (error) {
      console.error("Error fetching download URL:", error);
    }
  };

  return (
    <>
      <section className="main-dashboard">
        <div className="top-button">
          <Button
            type="submit"
            onClick={downloadLink}
            className="backlink-button1"
          >
            <img className="img-icon" src={download} alt="Your Image" />{" "}
            Download Sample
          </Button>
        </div>
        <div className="add-link-box">
          <div className="row">
            <div className="col-12 col-lg-6 col-sm-12">
              <h2>Add your website</h2>
              <div className="rediobtn-form">
                {/* <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="exampleRadio"
                                        id="exampleRadio1"

                                        checked={selectedTab === "owned"}
                                        onChange={() => handleTabChange("owned")}

                                    />
                                    <label className="form-check-label" htmlFor="exampleRadio1">
                                        Owned
                                    </label>
                                </div> */}
                {/* <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="exampleRadio"
                                        id="exampleRadio1"
                                        checked={selectedTab === "referenced"}
                                        onChange={() => handleTabChange("referenced")}

                                    />
                                    <label className="form-check-label" htmlFor="exampleRadio1">
                                        Referenced
                                    </label>
                                </div> */}
              </div>
              {selectedTab === "owned" && <AddWoned />}
              {selectedTab === "referenced" && <AddRefrence />}
            </div>

            <div className="col-12 col-lg-6 col-sm-12">
              <div className="upload-box">
                <p className="upload-tittle-top">Upload Links</p>
                <p className="upload-tittle-top2">
                  Upload Multiple links with Excel.
                </p>

                <div class="file-input mt-3">
                  <label class="add-card pt-50 pb-50">
                    <div class="add-icon">
                      <img src={uploadicon} alt="Your Image" />
                    </div>
                    <p class="add-file-type">Select a file to import</p>
                    <p class="add-file-type2">or drag and drop it here</p>
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      // accept=".doc,.docx,.pdf"
                      multiple
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
                {/* {uploadError ? (
                                    <div className="no-error">
                                        <img src={check} alt="Success" /> Success Message
                                    </div>
                                ) : (
                                    <div className="yes-error">
                                        <img src={dell} alt="Error" /> Error Message here...
                                    </div>
                                )} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
