import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Routess } from "../../routes";
import {
  faEnvelope,
  faUnlockAlt,
  faKey,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useNavigate  } from "react-router-dom";
// import {login} from "../../actions/index"
import { login } from "../../reducers/loginSlice";
import loginlogo from "../../assets/img/logo tracklink 1.png";
import loginimg from "../../assets/img/logoin.PNG";
import googleimg from "../../assets/img/search 1.svg";
import metaeimg from "../../assets/img/Vector.svg";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../config/config";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import "./singin-up.css";
// import axios from "axios";
import Cookies from "js-cookie";
export default () => {
  const history = useNavigate ();
  // const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  // const loginUser = async (e) => {
  //   e.preventDefault();
  //   setLoader(true);
  //   const data = { login: email, password: password };
  //   try {
  //     const url = `${baseUrl}/api/accounts/login/`;
  //     const { data: res } = await axios.post(url, data);
  //     localStorage.setItem("token", res.token);
  //     if (rememberMe) {
  //       // Save email and password if "Remember Me" is checked
  //       localStorage.setItem("rememberedEmail", email);
  //       localStorage.setItem("rememberedPassword", password);
  //     } else {
  //       // Clear saved email and password if "Remember Me" is not checked
  //       localStorage.removeItem("rememberedEmail");
  //       localStorage.removeItem("rememberedPassword");
  //     }
  //     Cookies.set("token", res.token, { path: "/" });
  //     setLoader(false);
  //     toast.success("Login Successfully.");
  //     history.push("/dashboard/overview");
  //     // window.location.reload();
  //   } catch (error) {
  //     setLoader(false);
  //     if (
  //       error.response &&
  //       error.response.status >= 400 &&
  //       error.response.status <= 500
  //     ) {
  //       toast.error("Incorrect email or password.");
  //     }
  //   }
  // };

  const loginUser = async (e) => {
    e.preventDefault();
    setLoader(true);
    const data = { login: email, password: password };

    try {
      const url = `${baseUrl}/api/accounts/login/`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      toast.success('Login Successfully.');
      const res = await response.json();
      localStorage.setItem('trp_token', res.token);
      Cookies.set('trp_token', res.token, { path: '/' });
      if (rememberMe) {
        localStorage.setItem('rememberedEmail', email);
        localStorage.setItem('rememberedPassword', password);
      } else {
        localStorage.removeItem('rememberedEmail');
        localStorage.removeItem('rememberedPassword');
      }
      setTimeout(()=>{
        history('/');
        window.location.reload();
        setLoader(false);
      },1000)
    
    } catch (error) {
      setLoader(false);
      toast.error('Incorrect email or password.');
    }
  };

  useEffect(() => {
    // Load saved email and password when component mounts
    const savedEmail = localStorage.getItem("rememberedEmail");
    const savedPassword = localStorage.getItem("rememberedPassword");
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  // const loginUser = async (e) => {
  //   e.preventDefault();
  //   setLoader(true);
  //   const data = { login: email, password: password };
  //   try {
  //     const url = `${baseUrl}/api/accounts/login/`;
  //     const { data: res } = await axios.post(url, data);
  //     localStorage.setItem("token", res.token);
  //     Cookies.set("token", res.token, { path: "/" });
  //     setLoader(false);
  //     toast.success("Login Successfully.");
  //     history.push("/dashboard/overview");
  //   } catch (error) {
  //     setLoader(false);
  //     if (
  //       error.response &&
  //       error.response.status >= 400 &&
  //       error.response.status <= 500
  //     ) {
  //       toast.error("Incorrect email or password.");
  //     }
  //   }
  // };

  return (
    <main className="log-main">
      <section className="d-flex justify-content-center align-items-center my-5 mt-lg-3 mb-lg-3">
        <div className="container">
        <Row className="justify-content-center form-bg-image">
            <Col xs={12} md={6}>
              <div className="login-img">
                <img className="login-logo" src={loginlogo} alt="Your Image" />
                <img
                  className="login-img-main"
                  src={loginimg}
                  alt="Your Image"
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-4 w-100">
                <div className="login-text1">
                  <p>Login to your</p>
                  <h2>Track Link Pro Account</h2>
                </div>
                <Form className="mt-4" onSubmit={loginUser}>
                  <Form.Group id="email" className="mb-3">
                    <InputGroup>
                      <InputGroup.Text className="input-grp1">
                        <FontAwesomeIcon
                          style={{ color: "black" }}
                          icon={faEnvelope}
                        />
                      </InputGroup.Text>
                      <Form.Control
                        className="input-grp"
                        autoFocus
                        required
                        type="email"
                        name="email"
                        value={email}
                        // onChange={loginUser}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-3">
                      <InputGroup>
                        <InputGroup.Text className="input-grp1">
                          <FontAwesomeIcon
                            style={{ color: "black" }}
                            icon={faKey}
                          />
                        </InputGroup.Text>
                        <Form.Control
                          className="input-grp"
                          autoFocus
                          required
                          type="password"
                          name="password"
                          value={password}
                          // onChange={loginUser}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form.Group>
                  <div className="remeberme mb-3">
                    <Form.Group>
                      <Form.Check
                        className="login-check-box"
                        type="checkbox"
                        id="rememberMe"
                        label="Remember Me"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                    </Form.Group>
                    <div className="forgot-password-link">
                      <Link to="/forgotpassword">Forgot Password?</Link>
                    </div>
                  </div>

                  <Button
                    type="submit"

                    className="login-btn"
                  >
                    {!loader ? "Login" : "Login Process.."}
                  </Button>

                  <div className="register-link">
                    Don’t have an account?{" "}
                    <Link
                      className="register-link-link"
                      to={Routess.Signup.path}
                    >
                      Register
                    </Link>
                  </div>

                  {/* <div className="or">
                    <div className="or-line"></div>
                    <div className="or-or">OR</div>
                    <div className="or-line"></div>
                  </div> */}

                  {/* <div className="g-btn">
                    <img src={googleimg} alt="Your Image" />
                    <p>Login with Google</p>
                  </div>

                  <div className="g-btn">
                    <img src={metaeimg} alt="Your Image" />
                    <p>Login with Facebook</p>
                  </div> */}
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <Footer />
    </main>
  );
};
