import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import "./scss/volt.scss";
import "./print-styles.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";
import { GlobalProvider } from "./context/GlobalState";
import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import Signup from "./pages/examples/Signup";
import "react-loading-skeleton/dist/skeleton.css";

// Use createRoot to render the application
createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalProvider>
        <Provider store={store}>
          <ScrollToTop />
          <HomePage />
        </Provider>
      </GlobalProvider>
    </BrowserRouter>
  </React.StrictMode>
);
