import React, { useEffect, useState } from "react"; // Import React
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import success from "../../assets/img/success.png";
import styles from "./email-verify.css";
import { Fragment } from "react"; // Import Fragment from 'react'
import { useLocation } from "react-router-dom";
import { Container } from "@themesberg/react-bootstrap";
import NotFound from "./NotFound";
import { baseUrl } from "../../config/config";
import { toast } from "react-toastify";

const EmailVerify = () => {
  const [validUrl, setValidUrl] = useState(true);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const user_id = params.get("user_id");
  const timestamp = params.get("timestamp");
  const signature = params.get("signature");

  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        const url = `${baseUrl}/api/accounts/verify-registration/`;
        const response = await axios.post(url, {
          user_id,
          timestamp,
          signature,
        });
        if (response.data.detail) {
          setValidUrl(true);
          toast.success("Email verified successfully.");
        } else {
          console.error("Email verification failed");
          toast.error("Email verification failed.");
          setValidUrl(false);
        }
      } catch (error) {
        console.error(error);
        setValidUrl(false);
      }
    };
    verifyEmailUrl();
  }, [user_id, timestamp, signature]);

  return (
    <Fragment>
      {validUrl ? (
        <div className="container">
          <img src={success} alt="success_img" className={styles.success_img} />
          <h1>Email verified successfully</h1>
          <Link to="/">
            <button className="green_btn">Login</button>
          </Link>
        </div>
      ) : (
        <NotFound></NotFound>
      )}
    </Fragment>
  );
};

export default EmailVerify;
