import React from "react";
import BacklinkTable from "../components/BacklinkTable";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <>
      <section className="main-dashboard">
          <BacklinkTable />
      </section>
    </>
  );
};
