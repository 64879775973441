import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import close from "../../assets/img/Vector.svg";
import apiService from "../../apiServices/apiService";

const AddWebsiteModal = ({ show, handleClose, getData }) => {
  const [formData, setFormData] = useState([{ name: "", niche: "" }]);
  const [loader, setLoader] = useState(false);
  const [niche, setNiche] = useState([]);
  const [errorMsg, setErrorMag] = useState([]);
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFormData = [...formData];
    updatedFormData[index][name] = value;
    setFormData(updatedFormData);
  };

  const handleAddMore = () => {
    setFormData([...formData, { name: "", niche: "" }]);
  };

  const fetchNiche = async () => {
    const data = await apiService.fetchNicheName();
    setNiche(data);
  };

  useEffect(() => {
    fetchNiche();
  }, []);

  const handleRemove = (index) => {
    const links = [...formData];
    links.splice(index, 1);
    setFormData(links);
  };

  const postOwnedLink = async (e) => {
    e.preventDefault();
    setLoader(true);
    const data = await apiService?.postTrackLink(formData);
    setErrorMag(data);
    if (data?.data?.errors?.length === 0) {
      setTimeout(() => {
        setFormData([{ name: "", niche: "" }]);
        handleClose();
        // fetchNiche();
      }, 3000);
    } else {
      // this is the logic for remove added data form tha form aaray
      const indices = data?.response?.data?.errors?.map((error) => error.index);
      const indicesToKeep = indices || [];
      indicesToKeep?.sort((a, b) => b - a);
      const filteredFormData = formData.filter((item, index) =>
        indicesToKeep.includes(index)
      );
      setTimeout(() => {
        setFormData(filteredFormData);
      }, 1000);
    }
    setLoader(false);
    getData();
  };

  // console.log("data", errorMsg);
  return (
    <>
      <Modal
        as={Modal.Dialog}
        show={show}
        // show={showModal1}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Add your website</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form method="POST" onSubmit={postOwnedLink}>
            <div className="add-from-link">
              <Form.Group className="form-group">
                <Form.Label>
                  {" "}
                  Your Website{" "}
                  {errorMsg?.response?.status === 400 &&
                    errorMsg?.response?.data?.errors &&
                    errorMsg?.response?.data?.errors[0]?.errors && (
                      <span className="text-danger">
                        {errorMsg?.response?.data?.errors[0]?.errors?.name
                          ? errorMsg?.response?.data?.errors[0]?.errors?.name[0]
                          : errorMsg?.response?.data?.errors[0]?.errors}
                      </span>
                    )}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="name"
                  value={formData[0].name}
                  onChange={(event) => handleInputChange(0, event)}
                  placeholder="Enter website URL"
                />
              </Form.Group>

              {/* <Form.Group className="form-group">
                    <Form.Label> Niche</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name="niche"
                        value={formData[0].niche}
                        onChange={(event) => handleInputChange(0, event)}
                        placeholder="Paintings"
                    />
                </Form.Group> */}

              <Form.Group className="form-group" id={`niche-${0}`}>
                <Form.Label>Niche</Form.Label>
                <Form.Select
                  required
                  name="niche"
                  value={formData[0].niche}
                  onChange={(event) => handleInputChange(0, event)}
                >
                  <option value="" disabled>
                    Select Niche
                  </option>
                  {niche?.map((item, ind) => (
                    <option key={item.id} value={item.id}>
                      {item?.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            {formData.slice(1).map((link, index) => (
              <div className="add-from-link" key={index}>
                <div>
                  <Form.Group className="form-group">
                    <div className="close-set">
                      <Form.Label>
                        {" "}
                        Your Website {"  "}
                        {errorMsg?.response?.status === 400 &&
                          errorMsg?.response?.data?.errors &&
                          errorMsg?.response?.data?.errors[index + 1]
                            ?.errors && (
                            <span className="text-danger">
                              {errorMsg?.response?.data?.errors[index + 1]
                                ?.errors?.name
                                ? errorMsg?.response?.data?.errors[index + 1]
                                    ?.errors?.name[0]
                                : errorMsg?.response?.data?.errors[index + 1]
                                    ?.errors}
                            </span>
                          )}
                      </Form.Label>
                      <Button
                        className="close-btn"
                        type="button"
                        onClick={() => handleRemove(index + 1)}
                      >
                        <img src={close} alt="Your Image" />
                      </Button>
                    </div>

                    <Form.Control
                      required
                      type="text"
                      name="name"
                      value={link.name}
                      onChange={(event) => handleInputChange(index + 1, event)}
                      placeholder="Enter website URL"
                    />
                  </Form.Group>
                  <Form.Group className="form-group" id={`niche-${index + 1}`}>
                    <Form.Label>Niche</Form.Label>
                    <Form.Select
                      required
                      name="niche"
                      value={link.niche}
                      onChange={(event) => handleInputChange(index + 1, event)}
                    >
                      <option value="" disabled>
                        Select Niche
                      </option>
                      {niche?.map((item, ind) => (
                        <option key={ind} value={item.id}>
                          {item?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
            ))}

            <div className="mt-3 mt-3 d-flex mb-3">
              <Button
                className="backlink-button1"
                variant="primary"
                type="submit"
              >
                {loader ? "Adding " : "Save"}
              </Button>

              <Button
                className="backlink-button2 d-flex ms-auto align-items-center"
                type="button"
                onClick={handleAddMore}
              >
                <FontAwesomeIcon className="btn-icon" icon={faPlusCircle} /> Add
                More
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddWebsiteModal;
