import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Table,
  Button,
  Tooltip,
  Modal,
  Form,
  Popover,
  Image,
} from "@themesberg/react-bootstrap";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import detail from "../../assets/img/View_light.png";
import addmore from "../../assets/img/Chat_plus_light.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faPen,
  faPlusCircle,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import ArrowLeftIcon from "../../assets/img/Arrow_left.png";
import Arrowup from "../../assets/img/Expand_up.png";
import gps from "../../assets/img/Gps_fixed.png";
import verified from "../../assets/img/Chield_check.png";
import verified2 from "../../assets/img/Chield_alt.png";
import orderss from "../../assets/img/Order.png";
import trancger from "../../assets/img/Transger.png";
import time from "../../assets/img/Time.png";
import check from "../../assets/img/check_ring_round.png";
import speed from "../../assets/img/Speed_alt.svg";
import info from "../../assets/img/info.svg";
import info2 from "../../assets/img/Info.png";
import filter from "../../assets/img/arrows.svg";
import linkalt from "../../assets/img/link_alt.svg";
import apiService from "../../apiServices/apiService";
import trash from "../../assets/img/Trash_light.png";
import GreenBadges from "../../assets/img/green-badge 1.png";
import YellowBadges from "../../assets/img/yellow-badge-thumb.png";
import alert from "../../assets/img/Bell_pin.svg";
import mail from "../../assets/img/mail.png";
import RedBadges from "../../assets/img/red-badge 1.png";
import close from "../../assets/img/Vector.svg";
import check2 from "../../assets/img/Check_fill.png";
import dell from "../../assets/img/Dell_fill.png";
import AddRefrenceModel from "../components/AddRefrenceModel";
import EditModel from "../components/EditModel";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import DeleteModal from "../components/DeleteModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { EditContact } from "../components/EditContact";
import LinkInNewTab from "../components/LinkInNewTab";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  TableContainer,
  IconButton,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  TablePagination,
} from "@mui/material";
import "../../../src/style.css";
import { Dropdown } from "react-bootstrap";
import { Icon } from "@iconify/react";
const AccordionItem = ({ title, description, isOpen, onClick }) => (
  <div className={`accordion-item ${isOpen ? "open" : ""}`}>
    <div className="accordion-title" onClick={onClick}>
      {isOpen ? (
        <Icon icon="iconamoon:arrow-up-2" />
      ) : (
        <Icon icon="iconamoon:arrow-down-2" />
      )}
      {title}
    </div>
    {isOpen && <div className="accordion-content">{description}</div>}
  </div>
);

const CustomAccordion = ({ data }) => {
  const [openItems, setOpenItems] = useState([0]);

  const toggleAccordion = (index) => {
    if (index === 0) {
      setOpenItems(openItems.filter((item) => item !== index));
    }

    if (openItems.includes(index)) {
      setOpenItems(openItems.filter((item) => item !== index));
    } else {
      setOpenItems([...openItems, index]);
    }
  };

  return (
    <div className="custom-accordion">
      {data &&
        data?.map((item, index) => (
          <AccordionItem
            key={index}
            title={item.title}
            description={item.description}
            isOpen={openItems.includes(index)}
            onClick={() => toggleAccordion(index)}
          />
        ))}
    </div>
  );
};

const Detailpage = (props) => {
  const history = useNavigate();
  // const linkData = props?.location?.data || {};
  // const [collapseStates, setCollapseStates] = useState({});
  const [detailsData, setDetailsData] = useState({});
  const [backlinks, setBacklinks] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedBacklink, setSelectedBacklink] = useState(null);
  const [niche, setNiche] = useState([]);
  // const [hasData, setHasData] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refLink = decodeURIComponent(queryParams.get("~"));
  const refLinkId = parseInt(refLink);

  const fetchNiche = async () => {
    const data = await apiService.fetchNicheName();
    setNiche(data);
  };

  const fetchBacklinks = async () => {
    const detailsData = await apiService.fetchLinkById(refLinkId);
    setDetailsData(detailsData);
  };

  useEffect(() => {
    fetchBacklinks();
    fetchNiche();
  }, []);

  const fetchBacklinksData = () => {
    setBacklinks(props.backlinksData || []);
  };

  const handleOpenEditModal = (backlink) => {
    setSelectedBacklink(backlink);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedBacklink(null);
  };

  // Edit sec ---------

  const [isEditing, setIsEditing] = useState(false);
  const [isEditing1, setIsEditing1] = useState(false);
  const [editedValue, setEditedValue] = useState("");
  const [editedValue1, setEditedValue1] = useState("");

  useEffect(() => {
    setEditedValue(detailsData?.niche_name || "");
    setEditedValue1(detailsData?.name || "");
  }, [detailsData]);

  const handleInputChange = (event) => {
    setEditedValue(event.target.value);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleEdit1 = () => {
    setIsEditing1(true);
  };

  const handleUpadteName = async () => {
    const data = {
      name: editedValue1,
      niche: detailsData.niche,
    };
    const response = await apiService.updateTrackLink(detailsData.id, data);
    fetchBacklinks();
    setIsEditing1(false);
  };

  const handleClose1 = () => {
    setIsEditing1(false);
  };

  const handleUpadteNiche = async () => {
    const data = {
      name: detailsData.name,
      niche: editedValue,
    };
    const response = await apiService.updateTrackLink(detailsData.id, data);
    fetchBacklinks();
    setIsEditing(false);
  };

  const handleClose = () => {
    setIsEditing(false);
  };

  const handleDeleteBacklink = async (id) => {
    try {
      setDeleteItemId(null);
      await apiService.deleteRequstedBacklinks(id);
      fetchBacklinks();
    } catch (error) {
      console.error("Error deleting track link:", error.message);
    }
  };

  // send email notification

  const sendMail = async (id) => {
    const response = await apiService.sendEmail(id);
  };
  const [show, setShow] = useState(false);
  const handleClose2 = () => setShow(false);

  const handleShow = (item) => {
    setShow(true);
  };

  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [selectedBacklinkForEdit, setSelectedBacklinkForEdit] = useState(null);

  const handleOpenEditContactModal = (backlink) => {
    setSelectedBacklinkForEdit(backlink);
    setShowEditContactModal(true);
  };

  const handleCloseEditContactModal = () => {
    setShowEditContactModal(false);
    setSelectedBacklinkForEdit(null);
  };

  // console.log("detailsData",detailsData)

  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deletePermission, setDeletePermision] = useState(false);
  const handleOpenModal = (itemId) => {
    if (deletePermission) {
      handleDeleteBacklink(itemId);
    } else {
      setDeleteItemId(itemId);
    }
    // setShowModal(true);
  };

  const handleCloseModal = () => {
    setDeleteItemId(null);
  };

  const calculateInputWidth = () => {
    const inputWidth = detailsData?.name ? detailsData.name.length * 10 : 100;
    return inputWidth;
  };

  const [copied, setCopied] = useState(false);

  const handleCopy = (errormessageCodeRef) => {
    const codeToCopy = errormessageCodeRef.current.textContent;
    navigator.clipboard.writeText(codeToCopy);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  const errormessageCodeRef = useRef(null);

  const copyVerificationCode = (verificationCodeRef) => {
    const codeToCopy = verificationCodeRef.current.textContent;
    navigator.clipboard.writeText(codeToCopy);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const verificationCodeRef = useRef(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth <= 998);
  };
  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);
  const accordionData = [
    {
      id: 1,
      title: "Tracking Details",
      description: (
        <div>
          <Table>
            <tbody>
              <tr
                className="table-row"
                style={{ borderBottom: "1px solid #E6EDFF" }}
              >
                <td className="border-0" style={{ width: "300px" }}>
                  {isEditing ? (
                    <div>
                      <div className="detail-container ">
                        <img src={gps} alt="Your Image" />
                        <span
                          className="detail-tittle"
                          style={{ marginRight: "5px" }}
                        >
                          Niche:
                        </span>
                        <Form.Group className="form-group" id={`niche-${0}`}>
                          <Form.Select
                            className="inner-select-css"
                            required
                            name="niche"
                            // value={formData.niche}
                            onChange={(event) => handleInputChange(event)}
                          >
                            <option value={detailsData?.niche}>
                              {detailsData?.niche_name}
                            </option>
                            {niche?.map((item, ind) => (
                              <option key={item.id} value={item.id}>
                                {item?.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <div className="edit-buttons">
                          <Button
                            className="detail-btn-edt"
                            onClick={handleClose}
                          >
                            <img src={dell} alt="Your Image" />
                          </Button>
                          <Button
                            className="detail-btn-edt"
                            onClick={handleUpadteNiche}
                          >
                            <img src={check2} alt="Your Image" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="detail-container">
                      <img src={gps} alt="Your Image" />
                      <span
                        className="deatil-tittle"
                        style={{ marginRight: "5px" }}
                      >
                        Niche:{" "}
                      </span>
                      <span className="deatil-sub" style={{ marginTop: "" }}>
                        {detailsData?.niche_name}
                      </span>
                      <Button className="edit-detai-btn1" onClick={handleEdit}>
                        <FontAwesomeIcon icon={faPen} />
                      </Button>
                    </div>
                  )}
                </td>
                <td className="border-0" style={{ width: "300px" }}>
                  <div className="detail-container">
                    <img src={verified} alt="Your Image" />
                    <span className="deatil-tittle">Verified:</span>{" "}
                    <span className="deatil-sub" style={{ paddingLeft: "5px" }}>
                      {detailsData?.dns_verified ? "True" : "False"}
                    </span>
                  </div>
                </td>
                <td
                  className="border-0 "
                  style={{
                    width: "300",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <div className="detail-container">
                    <img src={verified2} alt="Your Image" />
                    <span className="deatil-tittle">
                      Verification code:
                    </span>{" "}
                    <OverlayTrigger
                      trigger={["click", "hover", "focus"]}
                      placement="top"
                      delay={{ hide: 1000 }}
                      overlay={
                        <Popover
                          style={{ width: "250px" }}
                          id="verification-popover"
                        >
                          <Popover.Content>
                            {`tracklinkpro-site-verification_${detailsData?.dns_verification_identifier}`}
                            <Button
                              style={{ marginLeft: "10px" }}
                              variant="secondary"
                              size="sm"
                              onClick={() =>
                                copyVerificationCode(verificationCodeRef)
                              }
                            >
                              {copied ? (
                                <span style={{ marginLeft: "5px" }}>
                                  Copied!
                                </span>
                              ) : (
                                "Copy"
                              )}
                            </Button>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <span
                        className="deatil-sub"
                        style={{ cursor: "pointer", paddingLeft: "5px", width: "200px", maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis" }}
                        ref={verificationCodeRef}
                      >
                        {`tracklinkpro-site-verification_${detailsData?.dns_verification_identifier}`}
                      </span>
                    </OverlayTrigger>
                  </div>
                </td>
              </tr>

              <tr
                className="table-row"
                style={{ borderBottom: "1px solid #E6EDFF" }}
              >
                <td className="border-0" style={{ width: "300px" }}>
                  <Image src={orderss} alt="Your Image" />
                  <span className="deatil-tittle">Service page: </span>{" "}
                  <span className="deatil-sub">
                    {detailsData?.num_of_service_pages}
                  </span>
                </td>
                <td className="border-0" style={{ width: "300px" }}>
                  <img src={trancger} alt="Your Image" />
                  <span className="deatil-tittle">Outbound Links: </span>{" "}
                  <span className="deatil-sub">
                    {detailsData?.num_of_live_outgoing_links}
                  </span>
                </td>
                <td className="border-0">
                  <img src={trancger} alt="Your Image" />
                  <span className="deatil-tittle">Live backlinks: </span>{" "}
                  <span className="deatil-sub">
                    {detailsData?.num_of_live_incoming_links}
                  </span>
                </td>
              </tr>

              <tr
                className="table-row"
                style={{ borderBottom: "1px solid #E6EDFF" }}
              >
                <td
                  className="border-0"
                  style={{
                    width: "300px",
                    maxWidth: "325px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <img src={time} alt="Your Image" />
                  <span className="deatil-tittle">Last Checked On: </span>{" "}
                  <span className="deatil-sub">
                    {detailsData?.last_scraped_at &&
                      new Date(detailsData?.last_scraped_at).toLocaleString(
                        "en-US"
                      )}
                  </span>
                </td>
                <td
                  className="border-0"
                  style={{
                    width: "250px",
                    maxWidth: "250px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <img src={time} alt="Your Image" />
                  <span className="deatil-tittle">
                    Next Check Scheduled On:{" "}
                  </span>{" "}
                  <span className="deatil-sub">
                    {new Date(detailsData?.next_scraping_at).toLocaleString(
                      "en-US"
                    )}
                  </span>
                </td>
                <td className="border-0">
                  <img src={check} alt="Your Image" />
                  <span className="deatil-tittle">Current Check Status:</span>
                  {detailsData?.state && (
                    <span className="badges">{detailsData?.state}</span>
                  )}
                </td>
              </tr>
              <tr
                className="table-row"
                style={{ borderBottom: "1px solid #E6EDFF" }}
              >
                <td className="border-0">
                  <img
                    style={{ marginBottom: "4px" }}
                    src={speed}
                    alt="Your Image"
                  />
                  <span className="deatil-tittle">Total Checks Performed:</span>
                  <span className="deatil-sub">
                    {" "}
                    {detailsData?.scraping_count}{" "}
                  </span>
                </td>
                <td className="border-0">
                  <img
                    src={speed}
                    style={{ marginBottom: "4px" }}
                    alt="Your Image"
                  />
                  <span className="deatil-tittle">
                    Number of Unsuccessful Checks:{" "}
                  </span>
                  <span className="deatil-sub">
                    {detailsData?.failed_count}
                  </span>
                </td>
                <td
                  className="border-0"
                  style={{
                    width: "300px",
                    maxWidth: "300px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <img src={info2} alt="Your Image" />
                  <span className="deatil-tittle">Error Message: </span>

                  <OverlayTrigger
                    trigger={["click", "hover", "focus"]}
                    placement="top"
                    delay={{ hide: 1000 }}
                    overlay={
                      <Popover
                        style={{ width: "70%" }}
                        id="popover-error-message"
                      >
                        <Popover.Content>
                          {detailsData?.error_message}
                          <Button
                            style={{ marginLeft: "10px" }}
                            variant="secondary"
                            size="sm"
                            onClick={() => handleCopy(errormessageCodeRef)}
                          >
                            {copied ? (
                              <span style={{ marginLeft: "5px" }}>Copied!</span>
                            ) : (
                              "Copy"
                            )}
                          </Button>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <span
                      className="greenext"
                      style={{ cursor: "pointer" }}
                      ref={errormessageCodeRef}
                    >
                      {detailsData?.error_message}
                    </span>
                  </OverlayTrigger>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      ),
    },
    {
      id: 2,
      title: (
        <div className="back-hints2">
          <div>
            {`Expected Backlinks (${detailsData?.requested_backlinks?.length || 0
              })`}
          </div>
          <div style={{ marginLeft: "10px" }} className="hints-box">
            <span className="hintss">Hints : </span>
            <span className="hints" style={{ marginLeft: "10px" }}>
              <Image src={GreenBadges} alt="Your Image" /> : Correct Backlink
              Found.
            </span>
            <span className="hints2">
              <Image
                style={{ width: "23px", marginRight: "3px" }}
                src={YellowBadges}
                alt="Your Image"
              />
              : Backlink Found, Anchor Differs.
            </span>
            <span className="hints2">
              <Image src={RedBadges} alt="Your Image" /> : Backlink Not Yet
              Found.
            </span>
          </div>
        </div>
      ),
      description: (
        <div>
          {/* mobile-view */}
          <div className="hints-box-mob">
            <span className="hints-mob" style={{ marginLeft: "15px" }}>
              <Image src={GreenBadges} alt="Your Image" /> Correct Backlink
              Found.
            </span>
            <span className="hints2-mob">
              <Image
                style={{ width: "23px", marginRight: "3px" }}
                src={YellowBadges}
                alt="Your Image"
              />
              Backlink Found, Anchor Differs.
            </span>
            <span className="hints2-mob">
              <Image src={RedBadges} alt="Your Image" /> Backlink Not Yet Found.
            </span>
          </div>
          {detailsData && detailsData.requested_backlinks?.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table
                  size="small"
                  aria-label="a dense table"
                  id="custom-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "25%" }}>
                        <TableSortLabel
                          active={orderBy === "name"}
                          direction={orderBy === "name" ? order : "asc"}
                          onClick={() => handleRequestSort("name")}
                        >
                          Website
                        </TableSortLabel>
                      </TableCell>
                      {isMobileView && (
                        <TableCell style={{ padding: "inherit" }}></TableCell>
                      )}
                      {isMobileView && (
                        <TableCell
                          style={{ textAlign: "center", width: "15%" }}
                        >
                          <TableSortLabel
                            active={orderBy === "anchor_matched"}
                            direction={
                              orderBy === "anchor_matched" ? order : "asc"
                            }
                            onClick={() => handleRequestSort("anchor_matched")}
                          >
                            Status
                          </TableSortLabel>
                        </TableCell>
                      )}

                      <TableCell style={{ width: "20%" }}>
                        <TableSortLabel
                          active={orderBy === "date"}
                          direction={orderBy === "date" ? order : "asc"}
                          onClick={() => handleRequestSort("date")}
                        >
                          Checked on
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: "15%" }}>
                        <TableSortLabel
                          active={orderBy === "anchor_text"}
                          direction={orderBy === "anchor_text" ? order : "asc"}
                          onClick={() => handleRequestSort("anchor_text")}
                        >
                          Desired Anchor Text
                        </TableSortLabel>
                      </TableCell>
                      {!isMobileView && (
                        <TableCell
                          style={{ textAlign: "center", width: "15%" }}
                        >
                          <TableSortLabel
                            active={orderBy === "anchor_matched"}
                            direction={
                              orderBy === "anchor_matched" ? order : "asc"
                            }
                            onClick={() => handleRequestSort("anchor_matched")}
                          >
                            Status
                          </TableSortLabel>
                        </TableCell>
                      )}
                      <TableCell style={{ width: "15%" }}>
                        <TableSortLabel
                          active={orderBy === "contact_name"}
                          direction={orderBy === "contact_name" ? order : "asc"}
                          onClick={() => handleRequestSort("contact_name")}
                        >
                          Contact
                        </TableSortLabel>
                      </TableCell>

                      {!isMobileView && (
                        <TableCell style={{ width: "10%" }}>Action</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(
                      (
                        (detailsData && detailsData?.requested_backlinks) ||
                        []
                      ).filter((item) =>
                        item.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ),
                      getComparator(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow className="table-row" key={row.id}>
                          <TableCell
                            className="tbl-detail cell-detail-page"
                            style={{
                              width: "25%",
                              maxWidth: "300px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <LinkInNewTab
                              className="link-col"
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              text={row?.name}
                              url={row?.name}
                            >
                              {" "}
                              {row?.name}{" "}
                            </LinkInNewTab>
                          </TableCell>
                          {isMobileView && (
                            //  <TableCell style={{position:"absolute", right:"10px",background: (deleteItemId?"gray":"white"), zIndex:"111" }}>
                            //    <Button
                            //      style={{ marginLeft: "-5px" }}
                            //      className="edit-detai-btn"
                            //      onClick={() => handleOpenEditModal(row)}
                            //    >
                            //      <FontAwesomeIcon icon={faPen} />
                            //    </Button>

                            //    <Button
                            //      className="view-tooltip-btn"
                            //      style={{
                            //        paddingLeft: "5px",
                            //        background: "transparent",
                            //        border: "none",
                            //      }}
                            //      disabled={deleteItemId === row.id}
                            //      onClick={() => handleOpenModal(row?.id)}
                            //    >
                            //      <Image
                            //        className="view-icon"
                            //        src={trash}
                            //        alt="Your Image"
                            //      />
                            //    </Button>

                            //    {row?.email && (
                            //      <OverlayTrigger
                            //        trigger={["hover", "focus"]}
                            //        overlay={<Tooltip>{row?.email}</Tooltip>}
                            //      >
                            //        {row?.email ? (
                            //          <Button
                            //            onClick={() => sendMail(row?.id)}
                            //            className="edit-detai-btn2"
                            //          >
                            //            <Image
                            //              className="view-icon"
                            //              src={alert}
                            //              alt="Your Image"
                            //            />
                            //          </Button>
                            //        ) : (
                            //          <Button className="edit-detai-btn2"></Button>
                            //        )}
                            //      </OverlayTrigger>
                            //    )}

                            //    <DeleteModal
                            //      show={deleteItemId === row.id}
                            //      handleClose={handleCloseModal}
                            //      handleConfirm={() =>
                            //        handleDeleteBacklink(row?.id)
                            //      }
                            //      setDeletePermision={setDeletePermision}
                            //    />
                            //  </TableCell>

                            // this is use for the bullet

                            <TableCell
                              style={{
                                position: "absolute",
                                right: "20px",
                                background: deleteItemId ? "gray" : "white",
                                zIndex: "111",
                                height: "49px",
                              }}
                            >
                              <DeleteModal
                                show={deleteItemId === row.id}
                                handleClose={handleCloseModal}
                                handleConfirm={() =>
                                  handleDeleteBacklink(row?.id)
                                }
                                setDeletePermision={setDeletePermision}
                              />{" "}
                              <Dropdown>
                                <Dropdown.Toggle className="costum-toggel">
                                  <div>
                                    <FontAwesomeIcon
                                      icon={faEllipsisVertical}
                                      style={{ fontSize: "22px" }}
                                    />
                                  </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item className="fw-bold">
                                    <FontAwesomeIcon icon={faPen} />
                                    <button
                                      onClick={() => handleOpenEditModal(row)}
                                      class="top-drop-menu-btn"
                                      variant="link"
                                    >
                                      Edit
                                    </button>
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item className="fw-bold">
                                    <Image
                                      className="view-icon"
                                      src={trash}
                                      alt="Your Image"
                                    />
                                    <button
                                      className="top-drop-menu-btn"
                                      disabled={deleteItemId === row.id}
                                      onClick={() => handleOpenModal(row?.id)}
                                    >
                                      Delete
                                    </button>
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item className="fw-bold">
                                    {row?.email ? (
                                      <div>
                                        <Image
                                          className="view-icon"
                                          style={{ width: "16px" }}
                                          src={mail}
                                          alt="Your Image"
                                        />
                                        <button
                                          onClick={() => sendMail(row?.id)}
                                          className="top-drop-menu-btn"
                                        >
                                          {" "}
                                          Send Mail
                                        </button>
                                      </div>
                                    ) : null}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </TableCell>
                          )}
                          {isMobileView && (
                            <TableCell
                              className="cell-detail-page"
                              style={{
                                textAlign: "center",
                                width: "15%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                paddingRight: "35px",
                              }}
                            >
                              <img
                                style={{ width: "23px" }}
                                src={
                                  row?.is_live && row?.anchor_matched
                                    ? GreenBadges
                                    : row?.is_live && !row?.anchor_matched
                                      ? YellowBadges
                                      : RedBadges
                                }
                                alt="Badge"
                              />
                            </TableCell>
                          )}
                          <TableCell
                            className="cell-detail-page"
                            style={{
                              width: "20%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {new Date(row?.date).toLocaleString("en-US")}
                          </TableCell>
                          <TableCell
                            className="cell-detail-page"
                            style={{
                              width: "15%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {row.anchor_text}
                          </TableCell>
                          {!isMobileView && (
                            <TableCell
                              className="cell-detail-page"
                              style={{
                                textAlign: "center",
                                width: "15%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                paddingRight: "35px",
                              }}
                            >
                              <img
                                style={{ width: "23px" }}
                                src={
                                  row?.is_live && row?.anchor_matched
                                    ? GreenBadges
                                    : row?.is_live && !row?.anchor_matched
                                      ? YellowBadges
                                      : RedBadges
                                }
                                alt="Badge"
                              />
                            </TableCell>
                          )}
                          <TableCell
                            className="cell-detail-page"
                            style={{
                              width: "15%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {row?.contact_name && (
                              <span className="link-col">
                                <Button
                                  className="detail-btn-edit"
                                  onClick={() =>
                                    handleOpenEditContactModal(row)
                                  }
                                >
                                  {row?.contact_name}
                                </Button>
                              </span>
                            )}
                          </TableCell>

                          {!isMobileView && (
                            <TableCell style={{ width: "10%" }}>
                              <Button
                                style={{ marginLeft: "-5px" }}
                                className="edit-detai-btn"
                                onClick={() => handleOpenEditModal(row)}
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </Button>

                              <Button
                                className="view-tooltip-btn"
                                style={{
                                  paddingLeft: "5px",
                                  background: "transparent", // Set background to transparent
                                  border: "none", // Optionally remove border if needed
                                }}
                                disabled={deleteItemId === row.id}
                                onClick={() => handleOpenModal(row?.id)}
                              >
                                <Image
                                  className="view-icon"
                                  src={trash}
                                  alt="Your Image"
                                />
                              </Button>

                              {row?.email && (
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  overlay={<Tooltip>{row?.email}</Tooltip>}
                                >
                                  {row?.email ? (
                                    <Button
                                      onClick={() => sendMail(row?.id)}
                                      className="edit-detai-btn2"
                                    >
                                      <Image
                                        className="view-icon"
                                        src={mail}
                                        alt="Your Image"
                                      />
                                    </Button>
                                  ) : (
                                    <Button className="edit-detai-btn2"></Button>
                                  )}
                                </OverlayTrigger>
                              )}

                              <DeleteModal
                                show={deleteItemId === row.id}
                                handleClose={handleCloseModal}
                                handleConfirm={() =>
                                  handleDeleteBacklink(row?.id)
                                }
                                setDeletePermision={setDeletePermision}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>

                <div className="pagination-box mb-4">
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={detailsData?.requested_backlinks?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    ActionsComponent={() => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={handlePrevPage}
                          disabled={page === 0}
                        >
                          <KeyboardArrowLeft />
                        </IconButton>
                        {[
                          ...Array(
                            Math.ceil(
                              (detailsData?.requested_backlinks?.length || 0) /
                              rowsPerPage
                            )
                          ).keys(),
                        ].map((pageNumber) => (
                          <IconButton
                            className={`pagination-no ${page === pageNumber ? "pegi-selected" : ""
                              }`}
                            key={pageNumber}
                            onClick={() => setPage(pageNumber)}
                            color={page === pageNumber ? "primary" : "default"}
                          >
                            <span className="pegi-table-no">
                              {pageNumber + 1}
                            </span>
                          </IconButton>
                        ))}
                        <IconButton
                          onClick={handleNextPage}
                          disabled={
                            page >=
                            Math.ceil(
                              (detailsData?.requested_backlinks?.length || 0) /
                              rowsPerPage
                            ) -
                            1
                          }
                        >
                          <KeyboardArrowRight />
                        </IconButton>
                      </div>
                    )}
                    labelRowsPerPage={null} // Hide the "Rows per page" label
                    labelDisplayedRows={({ from, to, count }) => null}
                  />
                </div>
              </TableContainer>
            </>
          ) : (
            <div className="nodata-found">No Data Found</div>
          )}
          {showEditModal && (
            <EditModel
              show={showEditModal}
              handleClose={handleCloseEditModal}
              userForEdit={selectedBacklink}
              wonedId={refLinkId}
              fetchContact={fetchBacklinksData}
              fetchBacklinks={fetchBacklinks}
            />
          )}

          {showEditContactModal && (
            <EditContact
              showEditModal={showEditContactModal}
              handleCloseEditModal={handleCloseEditContactModal}
              backlink={selectedBacklinkForEdit}
              fetchBacklinks={fetchBacklinks}
            />
          )}
        </div>
      ),
    },
    {
      id: 3,
      title: `Live Backlinks (${detailsData?.live_incoming_links?.length || 0
        })`,
      description: (
        <div>
          {detailsData && detailsData.live_incoming_links?.length > 0 ? (
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : "asc"}
                        onClick={() => handleRequestSort("name")}
                      >
                        Backlink Provider
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "date"}
                        direction={orderBy === "date" ? order : "asc"}
                        onClick={() => handleRequestSort("date")}
                      >
                        Discovered On
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "anchor_text"}
                        direction={orderBy === "anchor_text" ? order : "asc"}
                        onClick={() => handleRequestSort("anchor_text")}
                      >
                        Anchor
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort1(
                    (
                      (detailsData && detailsData?.live_incoming_links) ||
                      []
                    ).filter((item) =>
                      item.name.toLowerCase().includes(searchTerm.toLowerCase())
                    ),
                    getComparator(order, orderBy)
                  ).map((row) => (
                    <TableRow className="table-row" key={row.id}>
                      <TableCell
                        className="tbl-detail cell-detail-page"
                        style={{
                          width: "35%",
                          maxWidth: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <LinkInNewTab text={row?.name} url={row?.name} />
                      </TableCell>
                      <TableCell
                        className="cell-detail-page"
                        style={{
                          width: "25%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {new Date(row?.date).toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className="cell-detail-page"
                        style={{
                          width: "25%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row.anchor_text}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <div className="pagination-box mb-4">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={detailsData?.live_incoming_links?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  ActionsComponent={() => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={handlePrevPage}
                        disabled={page === 0}
                      >
                        <KeyboardArrowLeft />
                      </IconButton>
                      {[
                        ...Array(
                          Math.ceil(
                            (detailsData?.live_incoming_links?.length || 0) /
                            rowsPerPage
                          )
                        ).keys(),
                      ].map((pageNumber) => (
                        <IconButton
                          className={`pagination-no ${page === pageNumber ? "pegi-selected" : ""
                            }`}
                          key={pageNumber}
                          onClick={() => setPage(pageNumber)}
                          color={page === pageNumber ? "primary" : "default"}
                        >
                          <span className="pegi-table-no">
                            {pageNumber + 1}
                          </span>
                        </IconButton>
                      ))}
                      <IconButton
                        onClick={handleNextPage}
                        disabled={
                          page >=
                          Math.ceil(
                            (detailsData?.live_incoming_links?.length || 0) /
                            rowsPerPage
                          ) -
                          1
                        }
                      >
                        <KeyboardArrowRight />
                      </IconButton>
                    </div>
                  )}
                  labelRowsPerPage={null} // Hide the "Rows per page" label
                  labelDisplayedRows={({ from, to, count }) => null}
                />
              </div>
            </TableContainer>
          ) : (
            <div className="nodata-found">No Data Found</div>
          )}
        </div>
      ),
    },
    {
      id: 4,
      title: `Lost Backlinks (${detailsData.lost_incoming_links?.length || 0})`,
      description: (
        <div>
          {detailsData && detailsData.lost_incoming_links?.length > 0 ? (
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : "asc"}
                        onClick={() => handleRequestSort("name")}
                      >
                        Backlink Provider
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "date"}
                        direction={orderBy === "date" ? order : "asc"}
                        onClick={() => handleRequestSort("date")}
                      >
                        Lost On
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "anchor_text"}
                        direction={orderBy === "anchor_text" ? order : "asc"}
                        onClick={() => handleRequestSort("anchor_text")}
                      >
                        Anchor
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "contact_name"}
                        direction={orderBy === "contact_name" ? order : "asc"}
                        onClick={() => handleRequestSort("contact_name")}
                      >
                        Contact
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort2(
                    (
                      (detailsData && detailsData?.lost_incoming_links) ||
                      []
                    ).filter((item) =>
                      item.name.toLowerCase().includes(searchTerm.toLowerCase())
                    ),
                    getComparator(order, orderBy)
                  ).map((row) => (
                    <TableRow className="table-row" key={row.id}>
                      <TableCell
                        className="tbl-detail cell-detail-page"
                        style={{
                          width: "25%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <LinkInNewTab text={row?.name} url={row?.name} />
                      </TableCell>
                      <TableCell
                        className="cell-detail-page"
                        style={{
                          width: "25%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {new Date(row?.date).toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className="cell-detail-page"
                        style={{
                          width: "25%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row.anchor_text}
                      </TableCell>
                      <TableCell
                        className="cell-detail-page"
                        style={{
                          width: "25%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row.contact_name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <div className="pagination-box mb-4">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={detailsData?.lost_incoming_links?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  ActionsComponent={() => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={handlePrevPage}
                        disabled={page === 0}
                      >
                        <KeyboardArrowLeft />
                      </IconButton>
                      {[
                        ...Array(
                          Math.ceil(
                            (detailsData?.lost_incoming_links?.length || 0) /
                            rowsPerPage
                          )
                        ).keys(),
                      ].map((pageNumber) => (
                        <IconButton
                          className={`pagination-no ${page === pageNumber ? "pegi-selected" : ""
                            }`}
                          key={pageNumber}
                          onClick={() => setPage(pageNumber)}
                          color={page === pageNumber ? "primary" : "default"}
                        >
                          <span className="pegi-table-no">
                            {pageNumber + 1}
                          </span>
                        </IconButton>
                      ))}
                      <IconButton
                        onClick={handleNextPage}
                        disabled={
                          page >=
                          Math.ceil(
                            (detailsData?.lost_incoming_links?.length || 0) /
                            rowsPerPage
                          ) -
                          1
                        }
                      >
                        <KeyboardArrowRight />
                      </IconButton>
                    </div>
                  )}
                  labelRowsPerPage={null} // Hide the "Rows per page" label
                  labelDisplayedRows={({ from, to, count }) => null}
                />
              </div>
            </TableContainer>
          ) : (
            <div className="nodata-found">No Data Found</div>
          )}
        </div>
      ),
    },
    {
      id: 5,
      title: `Live Outbound Links (${detailsData.live_outgoing_links?.length || 0
        })`,
      description: (
        <div>
          {detailsData && detailsData.live_outgoing_links?.length > 0 ? (
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : "asc"}
                        onClick={() => handleRequestSort("name")}
                      >
                        Outbound Links given to
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "date"}
                        direction={orderBy === "date" ? order : "asc"}
                        onClick={() => handleRequestSort("date")}
                      >
                        Discovered On
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "anchor_text"}
                        direction={orderBy === "anchor_text" ? order : "asc"}
                        onClick={() => handleRequestSort("anchor_text")}
                      >
                        Anchor
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort3(
                    (
                      (detailsData && detailsData?.live_outgoing_links) ||
                      []
                    ).filter((item) =>
                      item.name.toLowerCase().includes(searchTerm.toLowerCase())
                    ),
                    getComparator(order, orderBy)
                  ).map((row) => (
                    <TableRow className="table-row" key={row.id}>
                      <TableCell
                        className="tbl-detail cell-detail-page"
                        style={{
                          width: "35%",
                          maxWidth: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <LinkInNewTab text={row?.name} url={row?.name} />
                      </TableCell>
                      <TableCell
                        className="cell-detail-page"
                        style={{
                          width: "25%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {new Date(row?.date).toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className="cell-detail-page"
                        style={{
                          width: "25%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row.anchor_text}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div className="pagination-box mb-4">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={detailsData?.live_outgoing_links?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  ActionsComponent={() => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={handlePrevPage}
                        disabled={page === 0}
                      >
                        <KeyboardArrowLeft />
                      </IconButton>
                      {[
                        ...Array(
                          Math.ceil(
                            (detailsData?.live_outgoing_links?.length || 0) /
                            rowsPerPage
                          )
                        ).keys(),
                      ].map((pageNumber) => (
                        <IconButton
                          className={`pagination-no ${page === pageNumber ? "pegi-selected" : ""
                            }`}
                          key={pageNumber}
                          onClick={() => setPage(pageNumber)}
                          color={page === pageNumber ? "primary" : "default"}
                        >
                          <span className="pegi-table-no">
                            {pageNumber + 1}
                          </span>
                        </IconButton>
                      ))}
                      <IconButton
                        onClick={handleNextPage}
                        disabled={
                          page >=
                          Math.ceil(
                            (detailsData?.live_outgoing_links?.length || 0) /
                            rowsPerPage
                          ) -
                          1
                        }
                      >
                        <KeyboardArrowRight />
                      </IconButton>
                    </div>
                  )}
                  labelRowsPerPage={null} // Hide the "Rows per page" label
                  labelDisplayedRows={({ from, to, count }) => null}
                />
              </div>
            </TableContainer>
          ) : (
            <div className="nodata-found">No Data Found</div>
          )}
        </div>
      ),
    },
    {
      id: 6,
      title: `Abandoned Outbound Links (${detailsData?.abandon_outgoing_links?.length || 0
        })`,
      description: (
        <div>
          {detailsData && detailsData?.abandon_outgoing_links?.length > 0 ? (
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : "asc"}
                        onClick={() => handleRequestSort("name")}
                      >
                        Outbound Links given to
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "date"}
                        direction={orderBy === "date" ? order : "asc"}
                        onClick={() => handleRequestSort("date")}
                      >
                        Abandoned on
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "anchor_text"}
                        direction={orderBy === "anchor_text" ? order : "asc"}
                        onClick={() => handleRequestSort("anchor_text")}
                      >
                        Anchor
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort4(
                    (
                      (detailsData && detailsData?.abandon_outgoing_links) ||
                      []
                    ).filter((item) =>
                      item.name.toLowerCase().includes(searchTerm.toLowerCase())
                    ),
                    getComparator(order, orderBy)
                  ).map((row) => (
                    <TableRow className="table-row" key={row.id}>
                      <TableCell
                        className="tbl-detail cell-detail-page"
                        style={{
                          width: "35%",
                          maxWidth: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <LinkInNewTab text={row?.name} url={row?.name} />
                      </TableCell>
                      <TableCell
                        className="cell-detail-page"
                        style={{
                          width: "25%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {new Date(row?.date).toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className="cell-detail-page"
                        style={{
                          width: "25%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row.anchor_text}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <div className="pagination-box mb-4">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={detailsData?.abandon_outgoing_links?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  ActionsComponent={() => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={handlePrevPage}
                        disabled={page === 0}
                      >
                        <KeyboardArrowLeft />
                      </IconButton>
                      {[
                        ...Array(
                          Math.ceil(
                            (detailsData?.abandon_outgoing_links?.length || 0) /
                            rowsPerPage
                          )
                        ).keys(),
                      ].map((pageNumber) => (
                        <IconButton
                          className={`pagination-no ${page === pageNumber ? "pegi-selected" : ""
                            }`}
                          key={pageNumber}
                          onClick={() => setPage(pageNumber)}
                          color={page === pageNumber ? "primary" : "default"}
                        >
                          <span className="pegi-table-no">
                            {pageNumber + 1}
                          </span>
                        </IconButton>
                      ))}
                      <IconButton
                        onClick={handleNextPage}
                        disabled={
                          page >=
                          Math.ceil(
                            (detailsData?.abandon_outgoing_links?.length || 0) /
                            rowsPerPage
                          ) -
                          1
                        }
                      >
                        <KeyboardArrowRight />
                      </IconButton>
                    </div>
                  )}
                  labelRowsPerPage={null} // Hide the "Rows per page" label
                  labelDisplayedRows={({ from, to, count }) => null}
                />
              </div>
            </TableContainer>
          ) : (
            <div className="nodata-found">No Data Found</div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <section className="main-dashboard">
        <div className="top-button">
          <Button
            className="backlink-button3"
            style={{ marginLeft: "7px" }}
            onClick={() => handleShow()}
          >
            <FontAwesomeIcon className="btn-icon" icon={faPlusCircle} />
            Add Expected Backlink Sources
          </Button>

          <Button
            style={{ backgroundColor: "#ff9219" }}
            type="button"
            className="backlink-button3"
            onClick={() => history(-1)}
          >
            <img className="img-icon" src={ArrowLeftIcon} alt="Your Image" />{" "}
            All Website
          </Button>
        </div>

        {/* Mobile-view */}
        <div className="top-button-detail-mob">
          <Button
            className="backlink-button3-mob"
            style={{ marginLeft: "7px" }}
            onClick={() => handleShow()}
          >
            <FontAwesomeIcon className="btn-icon-mob" icon={faPlusCircle} />
            Add Backlink Sources
          </Button>

          <Button
            style={{ backgroundColor: "#ff9219" }}
            type="button"
            className="backlink-button3-mob"
            onClick={() => history(-1)}
          >
            <img
              className="img-icon-mob"
              src={ArrowLeftIcon}
              alt="Your Image"
            />{" "}
            All Website
          </Button>
        </div>

        <div className="deatil-main-box">
          {isEditing1 ? (
            <div className="detail-container1">
              <span className="detail-tittle">
                <p>{detailsData?.name}</p>
              </span>
              <div className="inputbox">
                <Form.Group className="form-group" id={`niche-${0}`}>
                  <input
                    type="text"
                    value={editedValue1}
                    onChange={(e) => setEditedValue1(e.target.value)}
                    className="form-control"
                    style={{
                      width: `${calculateInputWidth()}px`,
                      maxWidth: "100%",
                    }}
                  ></input>
                </Form.Group>
                <div className="edit-buttons1">
                  <Button className="detail-btn-edt1" onClick={handleClose1}>
                    <img src={dell} alt="Your Image" />
                  </Button>
                  <Button
                    className="detail-btn-edt1"
                    onClick={handleUpadteName}
                  >
                    <img src={check2} alt="Your Image" />
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="detail-tittle">
              <p>
                {(
                  <LinkInNewTab
                    text={detailsData?.name}
                    url={detailsData?.name}
                  />
                ) || <Skeleton count={1} />}
              </p>
              {/*<Button className="edit-detai-btn1 " onClick={handleEdit1}>
                <FontAwesomeIcon icon={faPen} />
          </Button>*/}
            </div>
          )}
          <CustomAccordion data={accordionData} />
          {show && (
            <AddRefrenceModel
              show={show}
              handleClose={handleClose2}
              refLinkData={refLinkId}
              fetchBacklinks={fetchBacklinks}
              websiteName={detailsData?.name}
            />
          )}
        </div>
      </section>
    </>
  );
};

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function stableSort1(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function stableSort2(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function stableSort3(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function stableSort4(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default Detailpage;
