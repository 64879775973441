import { useEffect, useRef } from 'react';

const useDelayedExecution = (callback, delay) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      callbackRef.current();
    }, delay);

    return () => clearTimeout(timerId);
  }, [delay]);
};

export default useDelayedExecution;
