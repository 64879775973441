export const Routess = {
  // pages
  Presentation: { path: "/" },
  About: { path: "/about" },
  DashboardOverview: { path: "/dashboard/overview" },
  AdminDashboard: { path: "/admin/dashboard" },
  UserList: { path: "/transactions" },
  AddLink: { path: "/addlink" },
  DetailPage: { path: "/detailpage" },
  AddUser: { path: "/adduser" },
  UpdateUser: { path: "/user/:id" },
  UpdateAssign: { path: "/task/:id" },
  AssignTask: { path: "/assigntask" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Signin: { path: "/" },
  EmailVerify: { path: "/verify-user/" },
  Signup: { path: "/examples/sign-up" },
  Logout: { path: "/examples/log-out" },
  ChangePassword: { path: "/change-password" },
  ResetPassword: { path: "/reset-password" },
  FeedBack: { path: "/feedback-page" },
  ForgotPassword: { path: "/forgotpassword" },
  NotFound: { path: "/*" },
  DocsOverview: { path: "/documentation/overview" },
  Widgets: { path: "/widgets" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  WebsiteAddMob: { path: "/add-website" },
  ImportCSVMob: { path: "/import-csv" },
};
