import { Routess } from "../../routes";

import { faChartPie,faLink, faChartSimple } from "@fortawesome/free-solid-svg-icons";


export const sidebarData = [
  {
    title: "Dashboard",
    link: Routess.DashboardOverview.path,

    icon: faChartSimple,

    // showTo: "admin",
  },
  // {
    // title: "Add Links",
    // link: Routess.AddLink.path,

    // icon: faLink,

    // showTo: "admin",
  // },
];
