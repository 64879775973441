import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSyncAlt,
  faPlusCircle,
  faEllipsisVertical,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Image,
  Button,
  Popover,
  Form,
  FormControl,
  Dropdown,
} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from 'react-bootstrap/Tooltip';
import GreenBadges from "../../assets/img/green-badge 1.png";
import RedBadges from "../../assets/img/red-badge 1.png";
import upload from "../../assets/img/shape.svg";
import download from "../../assets/img/shape 1.svg";
import search from "../../assets/img/shape22.svg";
import info from "../../assets/img/info.svg";
import filter from "../../assets/img/arrows.svg";
import addimage from "../../assets/img/image 1.png";
import check from "../../assets/img/Check_fill.png";
import dell from "../../assets/img/Dell_fill.png";
import detail from "../../assets/img/View_light.png";
import trash from "../../assets/img/Trash_light.png";
import addmore from "../../assets/img/Chat_plus_light.png";
import { Link } from "react-router-dom";
import apiService from "../../apiServices/apiService";
import { toast } from "react-toastify";
import { imageBaseUrl } from "../../config/config";
import AddRefrenceModel from "./AddRefrenceModel";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import AddWebsiteModal from "../addLinks/AddWebsiteModal";
import DeleteModal from "../components/DeleteModal";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Tooltip } from "react-tooltip";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  TablePagination,
} from "@mui/material";

const BacklinkTable = () => {
  const [show, setShow] = useState(false);
  const [linkData, setLinkData] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setLinkData(item);
    setShow({ show: true, rowData: item });
  };
  const [hasData, setHasData] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [showErrorMessages, setShowErrorMessages] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  // const [token, setToken] = useState("")
  let token = localStorage.getItem("trp_token");
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [tableData, setTableData] = useState([]);

  const getData = async () => {
    try {
      const data = await apiService.fetchTackLink();
      setTableData(data);
      setHasData(data?.results?.length > 0);
      return data;
    } catch (error) {
      console.log("error during calling data", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getData();
        if (response) {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    const dataTimeout = setTimeout(fetchData, 1000);
    return () => {
      clearTimeout(dataTimeout);
    };
  }, []);

  const deleteLink = async (id) => {
    try {
      await apiService.deleteTrackLink(id);
      getData();
      toast.success("Track link deleted successfully");
    } catch (error) {
      console.error("Error deleting track link:", error.message);
    }
  };

  const downloadLink = async () => {
    try {
      const urlForDownload = await apiService.downloadSample();
      window.open(urlForDownload);
    } catch (error) {
      console.error("Error fetching download URL:", error);
    }
  };

  const refreshData = async () => {
    setIsLoading(true);
    try {
      const data = await getData();
    } catch (error) {
      console.log("error during refresh data", error);
    }
    setIsLoading(false);
  };
  //////////import CSV/////////
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    try {
      const response = await apiService.uploadFile(selectedFile);
      refreshData();
    } catch (error) {
      // Handle the error
      console.error("An error occurred:", error.message);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const sortedResults = tableData?.results?.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  const filteredItems = (sortedResults || []).filter((item) =>
    item?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // show verification and error
  const handleVerificationCodeCheckboxChange = () => {
    setShowVerificationCode(!showVerificationCode);
  };

  const handleErrorMessagesCheckboxChange = () => {
    setShowErrorMessages(!showErrorMessages);
  };
  // table responsive code

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth <= 998);
  };
  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deletePermission, setDeletePermision] = useState(false);
  const handleOpenModal = (itemId) => {
    if (deletePermission) {
      deleteLink(itemId);
    } else {
      setDeleteItemId(itemId);
    }
    // setShowModal(true);
  };

  const handleCloseModal = () => {
    setDeleteItemId(null);
  };

  // add website
  const [showModal1, setShowModal1] = useState(false);
  const handleOpenModal1 = () => {
    setShowModal1(true);
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  const [copied1, setCopied1] = useState(false);
  const errormessageCodeRef = useRef(null);

  const handleCopy = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied1(true);
        setTimeout(() => {
          setCopied1(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  const [copied, setCopied] = useState(false);

  const copyVerificationCode = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  const verificationCodeRef = useRef(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const goToAddWebsitePage = () => {
    navigate("/add-website");
  };
  const goToImportCsvPage = () => {
    navigate("/import-csv");
  };

  // const sortedData = stableSort((tableData?.results || []), getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      {/* top row buttons  */}
      <div className="top-button">
        <Button
          type="submit"
          onClick={(e) => {
            refreshData();
            toast.success("Data Refresh Success!");
          }}
          className="backlink-button1"
        >
          <FontAwesomeIcon className="btn-icon" icon={faSyncAlt} /> Refresh
        </Button>
        <Button
          className="backlink-button2 backlink-button2add"
          onClick={handleOpenModal1}
        >
          <FontAwesomeIcon className="btn-icon" icon={faPlusCircle} /> Add Your
          Website
        </Button>
        {showModal1 && (
          <AddWebsiteModal
            show={showModal1}
            handleClose={handleCloseModal1}
            getData={getData}
          />
        )}
        <input
          type="file"
          className="custom-file-input"
          id="customFile"
          style={{ display: "none" }}
          onChange={handleFileChange}
          // ref={fileInputRef}
        />
        <Button
          type="button"
          className="backlink-button3"
          onClick={handleButtonClick}
        >
          <Image
            style={{ marginBottom: "3px" }}
            className="img-icon"
            src={upload}
            alt="Your Image"
          />{" "}
          Import your websites
        </Button>

        <Button
          type="submit"
          onClick={downloadLink}
          className="backlink-button1"
        >
          <Image
            style={{ marginBottom: "3px", width: "20px" }}
            className="img-icon"
            src={download}
            alt="Your Image"
          />{" "}
          Download template
        </Button>
      </div>

      {/* Mobile-View */}
      <div className="top-button-mobile">
        <div className="top-btn-div">
          <Button
            type="submit"
            onClick={(e) => {
              refreshData();
              toast.success("Data Refresh Success!");
            }}
            className="mbil-btn1"
            style={{ background: "#ff9219" }}
          >
            <FontAwesomeIcon style={{ fontSize: "35px" }} icon={faSyncAlt} />
          </Button>
          <span className="top-btn-text">Refresh </span>
        </div>

        <div className="top-btn-div">
          <Button
            className="mbil-btn1"
            style={{ background: "#2bb269" }}
            onClick={handleOpenModal1}
          >
            <FontAwesomeIcon style={{ fontSize: "35px" }} icon={faPlusCircle} />
          </Button>
          <span className="top-btn-text">Add Your Website </span>
        </div>

        {showModal1 && (
          <AddWebsiteModal
            show={showModal1}
            handleClose={handleCloseModal1}
            getData={getData}
          />
        )}
        <input
          type="file"
          className="custom-file-input"
          id="customFile"
          style={{ display: "none" }}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <div className="top-btn-div">
          {/* <Button
            type="button"
            className="mbil-btn1"
            style={{ background: "#1118b9" }}
            onClick={goToImportCsvPage}
          >
            <Image
              style={{ marginBottom: "3px", width: "35px" }}
              src={upload}
              alt="Your Image"
            />{" "}
          </Button> */}
          <Button
            type="button"
            className="mbil-btn1"
            style={{ background: "#1118b9" }}
            onClick={handleButtonClick}
          >
            <Image
              style={{ marginBottom: "3px", width: "35px" }}
              src={upload}
              alt="Your Image"
            />
          </Button>
          <span className="top-btn-text">Import your websites</span>
        </div>

        <div className="top-btn-div">
          <Button
            type="submit"
            onClick={downloadLink}
            style={{ background: "#ff9219" }}
            className="mbil-btn1"
          >
            <Image
              style={{ marginBottom: "3px", width: "35px" }}
              src={download}
              alt="Your Image"
            />{" "}
          </Button>
          <span className="top-btn-text">Download template</span>
        </div>
      </div>

      {/* seconst row checkboxes  */}
      <div className="back-hints">
        <div className="hints-box">
          <span className="hintss">Hints : </span>
          <span className="hints" style={{ marginLeft: "10px" }}>
            {" "}
            <Image src={GreenBadges} alt="Your Image" /> More than Recommended
            Links
          </span>
          <span className="hints2">
            <Image src={RedBadges} alt="Your Image" /> Less than Recommended
            Links
          </span>
          <span className="hints2">
            {/* Recommended Links : {tableData?.results?.length || 0} */}
            Recommended Links : {5}
          </span>
        </div>

        <div className="check-box">
          <label>
            <input
              type="checkbox"
              checked={showErrorMessages}
              onChange={handleErrorMessagesCheckboxChange}
            />
            Show Error Messages
          </label>

          <label>
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              checked={showVerificationCode}
              onChange={handleVerificationCodeCheckboxChange}
            />
            <div
              style={{ cursor: "pointer" }}
              data-tooltip-id="my-tooltip-verification"
            >
              Show Verification Code
            </div>
            <Tooltip id="my-tooltip-verification" place="top-start">
              <div>
                Copy this code to your DNS CNAME TXT<br></br> records for
                verification of website ownership.
              </div>
            </Tooltip>
          </label>
        </div>
      </div>

      {/* for mobile view  */}
      <div className="back-hints-mobile">
        <div className="hints-box-mobile">
          <div className="check-box">
            <label>
              <input
                type="checkbox"
                checked={showErrorMessages}
                onChange={handleErrorMessagesCheckboxChange}
              />
              Show Error Messages
            </label>

            <label>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                checked={showVerificationCode}
                onChange={handleVerificationCodeCheckboxChange}
              />
              <div
                style={{ cursor: "pointer" }}
                data-tooltip-id="my-tooltip-verification"
              >
                Show Verification Code
              </div>
              <Tooltip id="my-tooltip-verification" place="top-start">
                <div>
                  Copy this code to your DNS CNAME TXT<br></br> records for
                  verification of website ownership.
                </div>
              </Tooltip>
            </label>
          </div>

          <span className="hints">
            {" "}
            <Image src={GreenBadges} alt="Your Image" /> More than Recommended
            Link
          </span>
          <span className="hints2">
            <Image src={RedBadges} alt="Your Image" /> Less than Recommended
            Link
          </span>
        </div>
      </div>

      {/* for mobile view  */}

      {/* start table form here  */}

      {/* // this div use for search and title of table */}

      {isLoading ? (
        <SkeletonTheme
          baseColor="#e0e0e0"
          highlightColor="#f5f5f5"
          duration={5}
        >
          {/* Apply Skeleton component */}
          {/* <p>loading</p> */}
          <div style={{ padding: "20px" }}>
            {[...Array(10)].map((_, index) => (
              <div key={index} style={{ marginBottom: "15px" }}>
                <Skeleton height={20} width={`100%`} />
              </div>
            ))}
          </div>
        </SkeletonTheme>
      ) : (
        <div className="tables-section">
          {!isLoading && hasData && (
            <div className="table-tittle-search-box">
              <h3 className="table-tittle">Your Backlink Profile</h3>

              <Form className="search-form">
                <FormControl
                  type="Search"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Image src={search} alt="Your Image" />
              </Form>
            </div>
          )}

          {isMobileView ? (
            <div>
              {stableSort(
                      (tableData?.results || []).filter((item) =>
                        item.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ),
                      getComparator(order, orderBy)
                    ) .map((item, index) => (
                <Card key={index} className="mb-3">
                  <div className="mobile-top-btn-box">
                    <div
                      className={`mobile-btn-verify ${
                        item?.dns_verified ? "" : "not-verified"
                      }`}
                    >
                      {item?.dns_verified ? (
                        <>
                          <FontAwesomeIcon
                            className="mobile-icon-size"
                            icon={faCircleCheck}
                          />
                          Verified
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            className="mobile-icon-size"
                            icon={faCircleXmark}
                          />
                          Not Verified
                        </>
                      )}
                    </div>
                    <DeleteModal
                      show={deleteItemId === item?.id} // Change condition to match the selected row ID
                      handleClose={handleCloseModal}
                      handleConfirm={() => deleteLink(item?.id)}
                      setDeletePermision={setDeletePermision}
                    />
                    <Dropdown>
                      <Dropdown.Toggle className="costum-toggel">
                        <div className="">
                          <FontAwesomeIcon
                            icon={faEllipsisVertical}
                            style={{ fontSize: "22px" }}
                          />
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="user-dropdown ">
                        <Dropdown.Item className="fw-bold">
                          <Link
                            to={`/detailpage?~=${encodeURIComponent(item?.id)}`}
                          >
                            <Image
                              className="view-icon"
                              src={detail}
                              alt="Your Image"
                            />
                            <button
                              onTouchStart={(e) => e.preventDefault()}
                              onClick={()=>console.log("bhds")}
                              class="top-drop-menu-btn"
                             variant="link"
                            >
                              View
                            </button>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item className="fw-bold">
                          <Image
                            
                            className="view-icon"
                            src={trash}
                            alt="Your Image"
                          />
                          <button onClick={() => handleOpenModal(item?.id)} className="top-drop-menu-btn" >
                            Delete
                          </button>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item className="fw-bold">
                          <Image
                            className="view-icon"
                            src={addmore}
                            alt="Your Image"
                          />
                          <button
                            onClick={() => handleShow(item)}
                            className="top-drop-menu-btn"
                            onTouchStart={(e) => e.preventDefault()}
                          >
                            Expected
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <Card.Title className="custome-card-title" >
                  <Link to={`/detailpage?~=${encodeURIComponent(item.id)}`} className="custome-card-title">{item?.name}</Link>  
                  </Card.Title>
                  <div className="mobile-div-box">
                    <div
                      className="mobile-btn-links"
                      style={{
                        backgroundColor: "#e5e6ff",
                        border: "1px solid #b9bbf3",
                      }}
                    >
                      <div
                        style={{ background: "#1118b9" }}
                        className="mobile-number"
                      >
                        {item?.num_of_outgoing_links}
                      </div>
                      Outbound Links
                    </div>
                    <div
                      className="mobile-btn-links"
                      style={{
                        background: "#d5ffe8 ",
                        border: "1px solid #8ddeb3 ",
                      }}
                    >
                      <div
                        style={{ background: "#2bb269 " }}
                        className="mobile-number"
                      >
                        {item?.num_of_live_incoming_links}
                      </div>
                      Live Backlinks
                      {item?.num_of_live_incoming_links > 5 ? (
                        <Image
                          style={{ width: "13px", marginLeft: "3px" }}
                          src={GreenBadges}
                          alt="Your Image"
                        />
                      ) : (
                        <Image
                          style={{ width: "13px", marginLeft: "3px" }}
                          src={RedBadges}
                          alt="Your Image"
                        />
                      )}
                    </div>
                    <div
                      className="mobile-btn-links"
                      style={{
                        background: "#ffeedc",
                        border: "1px solid #e2aa6e",
                      }}
                    >
                      <div
                        style={{ backgroundColor: "#ff9219" }}
                        className="mobile-number"
                      >
                        {item?.num_of_lost_incoming_links}
                      </div>
                      Lost Backlinks
                    </div>
                  </div>
                  <div className="mob-description-box">
                    <div className="mob-des-box2">
                      <h5>Niche:</h5>
                      <h6>{item?.niche_name}</h6>
                    </div>
                    {showVerificationCode && (
                      <div className="mob-des-box3">
                        <h5>Verification Code:</h5>
                        <h6>{item?.dns_verification_identifier}</h6>
                      </div>
                    )}
                    {showErrorMessages && (
                      <div className="mob-des-box4">
                        <h5>Error:</h5>
                        <h6>{item?.error_message}</h6>
                      </div>
                    )}
                  </div>
                </Card>
              ))}
              <div className="pagination-box mb-4">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={tableData?.results?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  ActionsComponent={() => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={handlePrevPage}
                        disabled={page === 0}
                      >
                        <KeyboardArrowLeft />
                      </IconButton>
                      {[
                        ...Array(
                          Math.ceil(
                            (tableData?.results?.length || 0) / rowsPerPage
                          )
                        ).keys(),
                      ].map((pageNumber) => (
                        <IconButton
                          className={`pagination-no ${
                            page === pageNumber ? "pegi-selected" : ""
                          }`}
                          key={pageNumber}
                          onClick={() => setPage(pageNumber)}
                          color={page === pageNumber ? "primary" : "default"}
                        >
                          <span className="pegi-table-no">
                            {pageNumber + 1}
                          </span>
                        </IconButton>
                      ))}
                      <IconButton
                        onClick={handleNextPage}
                        disabled={
                          page >=
                          Math.ceil(
                            (tableData?.results?.length || 0) / rowsPerPage
                          ) -
                            1
                        }
                      >
                        <KeyboardArrowRight />
                      </IconButton>
                    </div>
                  )}
                  labelRowsPerPage={null} // Hide the "Rows per page" label
                  labelDisplayedRows={({ from, to, count }) => null}
                />
              </div>
            </div>
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          width: "200px",
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "name"}
                          direction={orderBy === "name" ? order : "asc"}
                          onClick={() => handleRequestSort("name")}
                        >
                          Your Websites
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          width: "115px",
                          maxWidth: "115px",
                          overflow: "hidden",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "dns_verified"}
                          direction={orderBy === "dns_verified" ? order : "asc"}
                          onClick={() => handleRequestSort("dns_verified")}
                        >
                          Verified
                          <Button
                            data-tooltip-id="my-tooltip-2"
                            className="tooltip-btn"
                          >
                            <Image src={info} alt="Your Image" />
                          </Button>
                        </TableSortLabel>

                        <Tooltip id="my-tooltip-2" place="top">
                          <div>
                            Green: Ownership verified via DNS CNAME TXT record
                            with our code. <br></br>
                            Red: Verification pending. Changes may take time to
                            reflect.
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        style={{
                          width: "130px",
                          maxWidth: "130px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "niche_name"}
                          direction={orderBy === "niche_name" ? order : "asc"}
                          onClick={() => handleRequestSort("niche_name")}
                        >
                          Niche
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          width: "164px",
                          maxWidth: "164px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "num_of_outgoing_links"}
                          direction={
                            orderBy === "num_of_outgoing_links" ? order : "asc"
                          }
                          onClick={() =>
                            handleRequestSort("num_of_outgoing_links")
                          }
                        >
                          Outbound Links
                          <Button
                            data-tooltip-id="my-tooltip-4"
                            className="tooltip-btn"
                          >
                            <Image src={info} alt="Your Image" />
                          </Button>
                        </TableSortLabel>
                        <Tooltip id="my-tooltip-4" place="top">
                          <div>
                            This number represents how many external links{" "}
                            <br></br>are on the website, pointing to other
                            websites.
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          width: "150px",
                          maxWidth: "150px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "num_of_live_incoming_links"}
                          direction={
                            orderBy === "num_of_live_incoming_links"
                              ? order
                              : "asc"
                          }
                          onClick={() =>
                            handleRequestSort("num_of_live_incoming_links")
                          }
                        >
                          Live backlinks
                          <Button
                            data-tooltip-id="my-tooltip-5"
                            className="tooltip-btn"
                          >
                            <Image src={info} alt="Your Image" />
                          </Button>
                        </TableSortLabel>
                        <Tooltip id="my-tooltip-5" place="top">
                          <div>
                            The number of active websites that have<br></br>{" "}
                            links pointing back to this website.
                          </div>
                        </Tooltip>
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          width: "150px",
                          maxWidth: "150px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "num_of_lost_incoming_links"}
                          direction={
                            orderBy === "num_of_lost_incoming_links"
                              ? order
                              : "asc"
                          }
                          onClick={() =>
                            handleRequestSort("num_of_lost_incoming_links")
                          }
                        >
                          Lost backlinks
                          <Button
                            data-tooltip-id="my-tooltip-6"
                            className="tooltip-btn"
                          >
                            <Image src={info} alt="Your Image" />
                          </Button>
                        </TableSortLabel>
                        <Tooltip id="my-tooltip-6" place="top">
                          <div>
                            Shows the number of websites that removed their
                            links<br></br> to this website since we began
                            monitoring.
                          </div>
                        </Tooltip>
                      </TableCell>

                      <TableCell
                        style={{
                          display: showVerificationCode ? "table-cell" : "none",
                          width: "200px",
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Verification Code
                        <Button
                          className="tooltip-btn"
                          data-tooltip-id="my-tooltip-7"
                        >
                          <Image src={info} alt="Your Image" />
                        </Button>
                        <Tooltip
                          id="my-tooltip-7"
                          place="top"
                          content="Verification Code"
                        />
                      </TableCell>

                      <TableCell
                        style={{
                          display: showErrorMessages ? "table-cell" : "none",
                          width: "200px",
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Error
                        <Button
                          className="tooltip-btn"
                          data-tooltip-id="my-tooltip-8"
                        >
                          <Image src={info} alt="Your Image" />
                        </Button>
                        <Tooltip
                          id="my-tooltip-8"
                          place="top"
                          content="Error"
                        />
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          width: "150px",
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(
                      (tableData?.results || []).filter((item) =>
                        item.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ),
                      getComparator(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row.id}>
                          <TableCell
                            className="list-cell-table"
                            style={{
                              width: "200px",
                              maxWidth: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Link
                              className="link-col"
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              to={`/detailpage?~=${encodeURIComponent(row.id)}`}
                            >
                              {" "}
                              {row?.name}{" "}
                            </Link>
                          </TableCell>

                          <TableCell
                            className="list-cell-table"
                            align="center"
                            style={{
                              width: "115px",
                              maxWidth: "115px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              paddingRight: "35px",
                            }}
                          >
                            <img
                              src={row.dns_verified ? check : dell}
                              alt={
                                row.dns_verified ? "Verified" : "Not Verified"
                              }
                            />
                          </TableCell>
                          <TableCell
                            className="list-cell-table"
                            style={{
                              width: "120px",
                              maxWidth: "120px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row.niche_name}
                          </TableCell>
                          <TableCell
                            className="list-cell-table"
                            align="center"
                            style={{
                              width: "120px",
                              maxWidth: "120px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row.num_of_outgoing_links}
                          </TableCell>
                          <TableCell
                            className="list-cell-table"
                            align="center"
                            style={{
                              width: "120px",
                              maxWidth: "120px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row?.num_of_live_incoming_links}{" "}
                            {row?.num_of_live_incoming_links > 5 ? (
                              <Image
                                className="table-icon"
                                src={GreenBadges}
                                alt="Your Image"
                              />
                            ) : (
                              <Image
                                className="table-icon"
                                src={RedBadges}
                                alt="Your Image"
                              />
                            )}
                          </TableCell>

                          <TableCell
                            className="list-cell-table"
                            align="center"
                            style={{
                              width: "120px",
                              maxWidth: "120px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row.num_of_lost_incoming_links}
                          </TableCell>
                          <TableCell
                            className="list-cell-table"
                            style={{
                              display: showVerificationCode
                                ? "table-cell"
                                : "none",
                              width: "100px",
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <div
                              className="pop-btn"
                              style={{ cursor: "pointer" }}
                              ref={verificationCodeRef}
                            >
                              {row?.dns_verification_identifier}
                            </div>
                            <div className="error-popup2">
                              <span>{row?.dns_verification_identifier}</span>
                              <Button
                                style={{
                                  marginLeft: "10px",
                                  padding: "0px 5px",
                                }}
                                variant="secondary"
                                size="sm"
                                onClick={() =>
                                  copyVerificationCode(
                                    row?.dns_verification_identifier
                                  )
                                }
                              >
                                {copied ? (
                                  <span style={{ marginLeft: "5px" }}>
                                    Copied!
                                  </span>
                                ) : (
                                  "Copy"
                                )}
                              </Button>
                            </div>
                          </TableCell>

                          <TableCell
                            className="list-cell-table"
                            style={{
                              display: showErrorMessages
                                ? "table-cell"
                                : "none",
                              width: "100px",
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <>
                              <div
                                className="pop-btn text-danger"
                                style={{ cursor: "pointer" }}
                                ref={errormessageCodeRef}
                              >
                                {row?.error_message}
                              </div>
                              <div className="error-popup">
                                <span
                                  className="text-danger"
                                  style={{
                                    height: "100px",
                                    maxHeight: "100px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {row?.error_message}
                                </span>
                                <Button
                                  style={{
                                    marginLeft: "10px",
                                    padding: "0px 5px",
                                  }}
                                  variant="secondary"
                                  size="sm"
                                  onClick={() => handleCopy(row?.error_message)}
                                >
                                  {copied1 ? (
                                    <span style={{ marginLeft: "5px" }}>
                                      Copied!
                                    </span>
                                  ) : (
                                    "Copy"
                                  )}
                                </Button>
                              </div>
                            </>
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ width: "100px", whiteSpace: "nowrap" }}
                          >
                            <Link
                              to={`/detailpage?~=${encodeURIComponent(row.id)}`}
                            >
                              <Button
                                className="view-tooltip-btn"
                                data-tooltip-id="my-tooltip-detail"
                                style={{ marginRight: "7px" }}
                              >
                                <Image
                                  className="view-icon"
                                  src={detail}
                                  alt="Your Image"
                                />
                              </Button>
                              <Tooltip id="my-tooltip-detail" place="top">
                                <div>
                                  View comprehensive details of your website,
                                  <br></br> including verification status,
                                  <br></br> backlinks, outbound links, and more.
                                </div>
                              </Tooltip>
                            </Link>

                            <Button
                              className="view-tooltip-btn"
                              onClick={() => handleOpenModal(row?.id)}
                            >
                              <Image
                                className="view-icon"
                                src={trash}
                                alt="Your Image"
                              />
                            </Button>
                            <DeleteModal
                              show={deleteItemId === row.id} // Change condition to match the selected row ID
                              handleClose={handleCloseModal}
                              handleConfirm={() => deleteLink(row.id)}
                              setDeletePermision={setDeletePermision}
                            />
                            <Button
                              className="view-tooltip-btn"
                              style={{ marginLeft: "7px" }}
                              onClick={() => handleShow(row)}
                              data-tooltip-id="my-tooltip-9"
                            >
                              <Image
                                className="view-icon"
                                src={addmore}
                                alt="Your Image"
                              />
                            </Button>
                            <Tooltip id="my-tooltip-9">
                              <div>
                                Add websites from<br></br>which you expect
                                <br></br> to receive backlinks.
                              </div>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>

                {hasData && (
                  <div className="pagination-box mb-4">
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[]}
                      count={tableData?.results?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      ActionsComponent={() => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            onClick={handlePrevPage}
                            disabled={page === 0}
                          >
                            <KeyboardArrowLeft />
                          </IconButton>
                          {[
                            ...Array(
                              Math.ceil(
                                (tableData?.results?.length || 0) / rowsPerPage
                              )
                            ).keys(),
                          ].map((pageNumber) => (
                            <IconButton
                              className={`pagination-no ${
                                page === pageNumber ? "pegi-selected" : ""
                              }`}
                              key={pageNumber}
                              onClick={() => setPage(pageNumber)}
                              color={
                                page === pageNumber ? "primary" : "default"
                              }
                            >
                              <span className="pegi-table-no">
                                {pageNumber + 1}
                              </span>
                            </IconButton>
                          ))}
                          <IconButton
                            onClick={handleNextPage}
                            disabled={
                              page >=
                              Math.ceil(
                                (tableData?.results?.length || 0) / rowsPerPage
                              ) -
                                1
                            }
                          >
                            <KeyboardArrowRight />
                          </IconButton>
                        </div>
                      )}
                      labelRowsPerPage={null} // Hide the "Rows per page" label
                      labelDisplayedRows={({ from, to, count }) => null}
                    />
                  </div>
                )}
              </TableContainer>
            </>
          )}
        </div>
      )}

      {!isLoading && !hasData && (
        <div className="add-link-screen">
          <Image className="img-icon" src={addimage} alt="Your Image" />
          <span className="add-link-screen-tittle">No Websites Added Yet </span>
          <span className="add-link-screen-sub-tittle">
            {" "}
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </span>
          <Button
            className="backlink-button2 backlink-button2add mt-3"
            onClick={handleOpenModal1}
          >
            <FontAwesomeIcon className="btn-icon" icon={faPlusCircle} /> Add
            Your Website
          </Button>
        </div>
      )}

      {show && (
        <AddRefrenceModel
          show={show}
          handleClose={handleClose}
          refLinkData={linkData.id}
        />
      )}
    </>
  );
};

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default BacklinkTable;
