import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Routess } from "../../routes";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useNavigate } from "react-router-dom";
import loginlogo from "../../assets/img/logo tracklink 1.png";
import loginimg from "../../assets/img/logoin.PNG";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../config/config";
import { toast } from "react-toastify";
import "./singin-up.css";
import axios from "axios";
export default () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const forgotUser = async (e) => {
    e.preventDefault();
    // Validation
    if (email.trim() === "") {
      toast.error("Email field is required");
      return;
    }
    setLoader(true);
    const data = { login: email };
    try {
      const url = `${baseUrl}/api/accounts/send-reset-password-link/`;
      const { data: res } = await axios.post(url, data);
      setLoader(false);
      toast.success("Send forgot password link Successfully.");
      setTimeout(() => {
        history("/");
      }, 2000);
    } catch (error) {
      setLoader(false);
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        toast.error("Incorrect email");
      }
    }
  };

  return (
    <main className="log-main mt-4">
      <section className="d-flex align-items-center my-5 mt-lg-3 mb-lg-3">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} md={6}>
              <div className="login-img">
                <img className="login-logo" src={loginlogo} alt="Your Image" />
                <img
                  className="login-img-main"
                  src={loginimg}
                  alt="Your Image"
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-4 w-100">
                <div className="login-text1">
                  <p>Forgot Password </p>
                  {/* <h2>Track Link Pro Account</h2> */}
                </div>
                <Form className="mt-4" method="POST">
                  <Form.Group id="email" className="mb-3">
                    <InputGroup>
                      <InputGroup.Text className="input-grp1">
                        <FontAwesomeIcon
                          style={{ color: "black" }}
                          icon={faEnvelope}
                        />
                      </InputGroup.Text>
                      <Form.Control
                        className="input-grp"
                        type="text"
                        name="email"
                        value={email}
                        // onChange={forgotUser}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                      />
                    </InputGroup>
                  </Form.Group>

                  <Button
                    type="submit"
                    onClick={forgotUser}
                    className="login-btn"
                  >
                    {!loader ? "Forgot Password" : "Process.."}
                  </Button>

                  <div className="register-link">
                    Back To{" "}
                    <Link
                      className="register-link-link"
                      to={Routess.Signin.path}
                    >
                      Login
                    </Link>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
