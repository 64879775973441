export default (state, action) => {
  switch (action.type) {
   
    case 'ADD_USER':
      return {
        ...state,
        users: [action.payload, ...state.users]
      }
      case 'ADD_DATA':
        return {
          ...state,
          datas: [action.payload, ...state.datas]
        }
      case 'ADD_IMAGES':
        return {
          ...state,
          images: [action.payload, ...state.images]
        }
    default:
      return state;
  }
}